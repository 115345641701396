import PAGES from '../pageTree';

export const reducer = (state, newState) => {
    return { ...state, ...newState };
};

export const getPageTitle = (location, objId) => {
    const title = 'Escola Nacional Florestan Fernandes';
    const locationParts = location.pathname.split('/');
    const pageKey = locationParts.length > 1 ? locationParts[1] : '';
    if (!pageKey || !Object.prototype.hasOwnProperty.call(PAGES, pageKey)) {
        return title;
    }
    const pageTitle = PAGES[pageKey].title;
    const subPageKey = locationParts.length > 2 ? locationParts[2] : '';
    if (
        !subPageKey ||
        !PAGES[pageKey].subPages ||
        !Object.prototype.hasOwnProperty.call(PAGES[pageKey].subPages, subPageKey)
    ) {
        return `${pageTitle} :: ${title}`;
    }
    const subPageInfo = PAGES[pageKey].subPages[subPageKey];
    const subPageTitle = subPageInfo.title;
    if (objId) {
        const action = objId === 'novo' ? 'Criação' : 'Edição';
        return pageKey === subPageKey
            ? `${subPageTitle} (${action}) :: ${title}`
            : `${subPageTitle} (${action}) :: ${pageTitle} :: ${title}`;
    }
    return pageKey === subPageKey
        ? `${subPageTitle} :: ${title}`
        : `${subPageTitle} :: ${pageTitle} :: ${title}`;
};

export function buildDefaultFilter(columnField, value) {
    if (!value) {
        return;
    }
    return {
        items: [
            {
                columnField,
                operatorValue: 'entre',
                value,
            },
        ],
    };
}

// Layout do formulário de pessoa para edição rápida
export function pessoaCustomFieldFormLayouts() {

    return {
        pessoa: [
            {
                title: 'Informações básicas',
                rows: [
                    'nome',
                    'email',
                    'telefone',
                    ['genero', 'data_de_nascimento'],
                    ['pais', 'uf'],
                    'idiomas',
                    'organizacao',
                    ['tipo_de_documento', 'documento'],
                ],
            },
            {
                title: 'Restrições',
                rows: [
                    'restricao_alimentar',
                    'cadeirante',
                    'baixa_visao',
                    'nao_subir_escada',
                    'nao_dormir_em_cima',
                ],
            },
            {
                title: 'Observações',
                rows: ['observacoes_sobre_saude'],
            },
        ],
    };

}

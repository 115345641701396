import React from 'react';
import Box from '@mui/material/Box';

export default function ResumoAtividadeBox({ label, content }) {
    if (label == "Vagas ocupadas" || label == "Vagas disponíveis" ) {
        var vagas_ocupadas = "<ul class='vagas_ocupadas'>";
        for (let bloco in content) {
            vagas_ocupadas += `<li>${bloco}`;
            vagas_ocupadas += `<ul>`;
            for (let quarto in content[bloco]) {
                vagas_ocupadas += `<li>${quarto}: ${content[bloco][quarto]}</li>`;
            }                        
            vagas_ocupadas += `</ul></li>`;
        }
        vagas_ocupadas += `</ul>`;
        content = vagas_ocupadas;
    }    

    return (
        <Box>
            <h3>{label}</h3>
            <p dangerouslySetInnerHTML={{ __html: content }}></p>
        </Box>
    );
}

import React, { useEffect, useState } from 'react';
import { ptBR } from 'date-fns/locale';
import { useAPIWrapper } from 'drf-react-by-schema';
import { useLocation } from 'react-router-dom';
import { Scheduler } from '@aldabil/react-scheduler';
import CONSTANTS from '../constants';
import Box from '@mui/material/Box';
import GenericPageList from '../pages/GenericPageList';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

export default function AtividadeList() {
    const columnFields = [
        'nome',
        'tipo_de_atividade',
        'inicio',
        'fim',
        'n_homens',
        'n_mulheres',
        'n_hospedagens',
    ];
    const minWidthFields = {
        actions: 300,
        nome: 380,
        organizacao: 380,
        total_previsto: 150,
        n_hospedagens: 105,
    };
    const indexField = 'nome';
    const sumRows = {
        rows: [
            {
                field: 'total_previsto',
                prefix: 'Total de educandas/os: ',
                isCount: true,
            },
            {
                field: 'n_homens',
                prefix: 'Total de homens: ',
                isCount: true,
            },
            {
                field: 'n_mulheres',
                prefix: 'Total de mulheres: ',
                isCount: true,
            },
        ],
    };
    const customColumnOperations = null;

    const { getRawData } = useAPIWrapper();

    const [modo, setModo] = useState('proximas');

    const getAtividadesMes = async (query) => {

        var inicio = `${query.start.getFullYear()}-${
            query.start.getMonth() + 1
        }-${query.start.getDate()}`;
        var fim = `${query.end.getFullYear()}-${query.end.getMonth() + 1}-${query.end.getDate()}`;  

        const newAtividades = await getRawData(`atividades_mes/?inicio=${inicio}&fim=${fim}`);

        if (newAtividades) {
            var atividades = [];
            for (var i = 0; i < newAtividades.data.length; i++) {
                atividades.push({
                    admin_id: newAtividades.data[i].id,
                    event_id: i,
                    title: newAtividades.data[i].nome,
                    start: new Date(newAtividades.data[i].inicio),
                    end: new Date(newAtividades.data[i].fim),
                    color: newAtividades.data[i].status == "Pendente" ? "#EEE" : CONSTANTS.CAL_COLORS[i % 5],
                    deletable: false,
                    editable: false,
                    status: newAtividades.data[i].status,
                });
            }
            return new Promise((res) => {
                res(atividades);
            });
        }
        console.log('Erro ao carregar atividades');
    };

    const getLotacaoMes = async (query) => {
        var inicio = `${query.start.getFullYear()}-${
            query.start.getMonth() + 1
        }-${query.start.getDate()}`;
        var fim = `${query.end.getFullYear()}-${query.end.getMonth() + 1}-${query.end.getDate()}`;        

        const newLotacaoMes = await getRawData(`lotacao_mes/?inicio=${inicio}&fim=${fim}`);
        if (newLotacaoMes) {            
            var lotacao_mes = [];
            for (var i = 0; i < Object.keys(newLotacaoMes).length; i++) {
                lotacao_mes.push({
                    admin_id: i,
                    event_id: i,
                    title: newLotacaoMes[Object.keys(newLotacaoMes)[i]],
                    start: new Date(`${Object.keys(newLotacaoMes)[i]}T12:00`),
                    end: new Date(`${Object.keys(newLotacaoMes)[i]}T12:00`),
                    disabled: true,
                    allday: true,
                });
            }
            return new Promise((res) => {
                res(lotacao_mes);
            });
        }
    };

    const handleModo = (event, newModo) => {
        setModo(newModo);
    };

    const PreListComponent = () => (
        <>
            <Box className="grid_wrapper" id="cal_atividades">
                <h2>Calendário de atividades</h2>
                <Scheduler
                    view="month"                  
                    height={800}
                    getRemoteEvents={getAtividadesMes}
                    locale={ptBR}
                    customViewer={(event) => {
                        return (
                          <div className='event_modal' style={{backgroundColor: event.color + "66"}}>
                            <p><span>{`${event.title}`}</span> <a href={`/atividade/${event.admin_id}`}><ModeEditIcon /></a><a href={`/atividade/detalhe/${event.admin_id}`}><VisibilityIcon /></a></p>
                            {
                                event.status == "Pendente" && (
                                    <p><b>{`${event.status}`}</b></p>
                                )
                            }
                            <p>De {event.start.toLocaleDateString()} a {event.end.toLocaleDateString()}</p>                            
                          </div>
                        );
                      }}
                    translations={{
                        loading: 'Carregando...',
                        navigation: {
                            month: 'Mês',
                            week: 'Semana',
                            day: 'Dia',
                            today: 'Hoje',
                            agenda: 'Agenda',
                        },
                        form: {
                            addTitle: 'Nova atividade',
                            editTitle: 'Editar atividade',
                            confirm: 'Confirmar',
                            delete: 'Apagar',
                            cancel: 'Cancelar',
                        },
                        event: {
                            title: 'Descrição',
                            start: 'Início',
                            end: 'Fim',
                            allDay: 'Dia inteiro',
                        },
                        moreEvents: 'Mais...',
                    }}
                    editable={false}
                />
            </Box>
            <Box className="grid_wrapper" id="cal_lotacao">
                <h2>Lotacão da Escola</h2>
                <Scheduler
                    view="month"
                    month={{
                        weekDays: [0, 1, 2, 3, 4, 5, 6],
                        weekStartOn: 1,
                        startHour: 9,
                        endHour: 17,
                        navigation: true,
                        disableGoToDay: true,
                    }}
                    week={{ 
                        weekDays: [0, 1, 2, 3, 4, 5, 6], 
                        weekStartOn: 1,
                        startHour: 9,
                        endHour: 17,
                        step: 60,
                        navigation: true,
                        disableGoToDay: true
                    }}
                    getRemoteEvents={getLotacaoMes}
                    editable={false}
                    deletable={false}
                    draggable={false}
                    disableViewNavigator={true}
                    locale={ptBR}
                />
            </Box>
            <ToggleButtonGroup value={modo} exclusive onChange={handleModo}>
                <ToggleButton value="proximas">Próximas</ToggleButton>
                <ToggleButton value="passadas">Passadas</ToggleButton>
                <ToggleButton value="em_andamento">Em andamento</ToggleButton>
                <ToggleButton value="">Todas</ToggleButton>
            </ToggleButtonGroup>
        </>
    );

    return (
        <>
            <h1>Atividades</h1>
            <GenericPageList
                columnFields={columnFields}
                indexField={indexField}
                sumRows={sumRows}
                minWidthFields={minWidthFields}
                hasHeader={true}
                customColumnOperations={customColumnOperations}
                PreListComponent={PreListComponent}
                queryParams={[`modo=${modo}`]}
            />
        </>
    );
}

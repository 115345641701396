import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import ListItemIcon from '@mui/material/ListItemIcon';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { clearJWT } from 'drf-react-by-schema';
import Grid from '@mui/material/Grid';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import PAGES from 'src/pageTree';
import enffSidebarIcon from '../imgs/Logomarca deitada.jpeg';

export default function TopBar({ usuaria, pageBasics, changeDayHandler, location }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [shouldShowDayPicker, setShouldShowDayPicker] = React.useState(true);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const key = PAGES[location.pathname === '/' ? 'home' : location.pathname.replace('/', '')];
        if (key) {
            setShouldShowDayPicker(key.showDayPicker === true);
        }
    }, [location.pathname]);

    return (
        <AppBar position="fixed" className="topbar">
            <Toolbar>
                <a href="/">
                    <img width={200} src={enffSidebarIcon} />
                </a>
                <Button
                    id="newHospedagem"
                    color="topBarButton"
                    variant="contained"
                    size="small"
                    href="/hospedagem/novo"
                    sx={{ ml: 7, mr: 2 }}
                    startIcon={<AddIcon />}>
                    Hospedagem
                </Button>
                <Button
                    id="newAtividade"
                    color="topBarButton"
                    variant="contained"
                    size="small"
                    href="/atividade/novo"
                    startIcon={<AddIcon />}>
                    Atividade
                </Button>
                {shouldShowDayPicker && (
                    <Grid item xs={3} marginLeft={3} textAlign="right">
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                            <DesktopDatePicker
                                label="Escolha o dia"
                                value={dayjs(pageBasics.day)}
                                onChange={(newValue) => {
                                    if(newValue && !isNaN(newValue.day())){
                                        changeDayHandler(newValue)
                                    }
                                }}
                                format="DD/MM/YYYY"
                            />
                        </LocalizationProvider>
                    </Grid>
                )}
                <Box sx={{ flexGrow: 1 }} />
                <Tooltip title="Configurações">
                    <Button
                        onClick={handleOpen}
                        color="topBarButtonText"
                        variant="text"
                        size="small"
                        endIcon={<KeyboardArrowDownIcon />}>
                        {usuaria.first_name || usuaria.username}
                    </Button>
                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                    <MenuItem
                        component={Link}
                        key="minhaconta"
                        to={`/settings/usuaria/${usuaria.id}`}
                        state={{ title: 'Meu perfil' }}>
                        <ListItemIcon>
                            <Avatar fontSize="small" />
                        </ListItemIcon>
                        Meu perfil
                    </MenuItem>
                    <MenuItem component={Link} key="configuracoesIndex" to="/settings">
                        <ListItemIcon>
                            <Settings fontSize="small" />
                        </ListItemIcon>
                        Configurações
                    </MenuItem>
                    <MenuItem
                        key="sair"
                        onClick={() => {
                            clearJWT();
                            window.location.reload();
                        }}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Desconectar
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
}

TopBar.propTypes = {
    usuaria: PropTypes.object,
};

import React from 'react';
import { Link, useOutletContext } from 'react-router-dom';

import GenericPageList from '../pages/GenericPageList';

export default function PessoaList() {
    /**
     * @type object
     */
    const { usuaria } = useOutletContext();
    const columnFields = ['nome', 'idade', 'genero', 'organizacao', 'telefone'];

    const minWidthFields = { organizacao: 250, email: 250, telefone: 200 };
    const indexField = 'nome';
    const sumRows = {
        rows: [],
    };
    const customColumnOperations = null;

    return (
        <GenericPageList
            columnFields={columnFields}
            indexField={indexField}
            sumRows={sumRows}
            minWidthFields={minWidthFields}
            hasHeader={true}
            customColumnOperations={customColumnOperations}
        />
    );
}

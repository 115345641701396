import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import { Hotel, MenuBook, People } from '@material-ui/icons';
import { Diversity3, Face2, VpnKey, Restaurant, AirportShuttle, ChildCare } from '@mui/icons-material';

const PAGES = {
    home: {
        title: 'Início',
        home: true,
        icon: <HomeIcon />,
        permission: null,
        skipRouter: true,
        showDayPicker: true,
    },
    atividade: {
        title: 'Atividades',
        icon: <MenuBook />,
        permission: null,
    },
    hospedagem: {
        title: 'Hospedagens',
        icon: <People />,
        permission: null,
    },
    pessoa: {
        title: 'Pessoas',
        icon: <Face2 />,
        permission: null,
    },
    equipe: {
        title: 'Brigada',
        icon: <Diversity3 />,
        permission: null,
    },    
    alojamento: {
        title: 'Alojamento',
        icon: <Hotel />,
        permission: null,
        skipRouter: true,
        showDayPicker: true,
    },
    cozinha: {
        title: 'Cozinha',
        icon: <Restaurant />,
        permission: null,
        skipRouter: true,
        showDayPicker: true,
    },
    portaria: {
        title: 'Portaria',
        icon: <VpnKey />,
        permission: null,
        skipRouter: true,
        showDayPicker: false,
    },
    transporte: {
        title: 'Transporte',
        icon: <AirportShuttle />,
        permission: null,
        skipRouter: true,
        showDayPicker: true,
    },
    ciranda: {
        title: 'Ciranda',
        icon: <ChildCare />,
        permission: null,
        skipRouter: true,
        showDayPicker: true,
    },    
};

export default PAGES;

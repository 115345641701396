import React, { useState } from 'react';
import { pessoaCustomFieldFormLayouts } from '../utils/utils';
import { GenericModelList } from 'drf-react-by-schema';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useLocation, useParams, Link, useOutletContext, Outlet } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function EquipeList() {
    /**
     * @type object
     */
    const columnFields = ['pessoa', 'setor', 'funcao', 'pessoa_organizacao', 'chegada', 'pessoa_email', 'pessoa_celular', 'pessoa_idiomas', 'pessoa_data_de_nascimento'];
    const minWidthFields = { pessoa: 250, setor: 250, funcao: 250, pessoa_organizacao: 250, pessoa_email: 250, pessoa_telefone: 200 };

    const columnFieldsCiranda = ['pessoa', 'equipe_responsaveis'];
    const minWidthFieldsCiranda = { pessoa: 400, equipe_responsaveis: 600 };
    
    const indexField = 'nome';
    const sumRows = {
        rows: [],
    };
    const customColumnOperations = null;

    const hiddenFields = [];
    /**
     * @type object
     */
    const context = useOutletContext();
    const pageBasics = context.pageBasics;
    const location = useLocation();    
    const pathParts = location.pathname.split('/');
    let model = pathParts[1] || null;
    let indexFieldBasePath = `/${model}/`;
    // let indexFieldViewBasePath = `/${model}/detalhe/`;
    const params = useParams();
    
    return (
        <>
            <Outlet context={context} />
            {!pageBasics.id && !params.objId && (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={11}>
                            <Typography variant="h1" sx={{ mb: 3 }}>
                                Brigada Apolônio de Carvalho
                            </Typography>                    
                        </Grid>
                        <Grid item xs={1}>                        
                            <Button
                                variant="contained"
                                href="/equipe/novo"
                                size="medium"
                                startIcon={<AddCircleOutlineIcon />}>
                                Adicionar
                            </Button>
                        </Grid>                  
                    </Grid>                    
                    <Box className="grid_wrapper">        
                        <Typography variant="h2" sx={{ mb: 3 }}>
                            Equipe Atual
                        </Typography>            
                        <GenericModelList
                            columnFields={columnFields}
                            hiddenFields={hiddenFields}
                            minWidthFields={minWidthFields}
                            indexField={indexField}
                            sumRows={sumRows}
                            customFieldFormLayouts={pessoaCustomFieldFormLayouts()}
                            customColumnOperations={customColumnOperations}
                            isAutoHeight={false}
                            tableAutoHeight={false}
                            model={'equipe'}
                            indexFieldBasePath={indexFieldBasePath}
                            forceReload={false}            
                            hasHeader={false}
                            paginationMode="server"
                            queryParams={[`modo=atuais`]}
                            actions={['editInline', 'edit']}
                            isEditable={true}
                            LinkComponent={Link}
                        />
                    </Box>            
                    <Box className="grid_wrapper">        
                        <Typography variant="h2" sx={{ mb: 3 }}>
                            Contratados/as
                        </Typography>            
                        <GenericModelList
                            columnFields={columnFields}
                            hiddenFields={hiddenFields}
                            minWidthFields={minWidthFields}
                            indexField={indexField}
                            sumRows={sumRows}
                            customFieldFormLayouts={pessoaCustomFieldFormLayouts()}
                            customColumnOperations={customColumnOperations}
                            isAutoHeight={false}
                            tableAutoHeight={false}
                            model={'equipe'}
                            indexFieldBasePath={`/equipe/`}
                            forceReload={false}            
                            hasHeader={false}
                            paginationMode="server"
                            queryParams={[`modo=funcionarias`]}
                            actions={['editInline', 'edit']}
                            isEditable={true}
                            LinkComponent={Link}
                        />
                    </Box>                        
                    <Box className="grid_wrapper">        
                        <Typography variant="h2" sx={{ mb: 3 }}>
                            Crianças
                        </Typography>            
                        <GenericModelList
                            columnFields={columnFieldsCiranda}
                            hiddenFields={hiddenFields}
                            minWidthFields={minWidthFieldsCiranda}
                            indexField={indexField}
                            sumRows={sumRows}
                            customFieldFormLayouts={pessoaCustomFieldFormLayouts()}
                            customColumnOperations={customColumnOperations}
                            isAutoHeight={false}
                            tableAutoHeight={false}
                            model={'equipe'}
                            indexFieldBasePath={`/equipe/`}
                            forceReload={false}            
                            hasHeader={false}
                            paginationMode="server"
                            queryParams={[`modo=ciranda`]}
                            actions={['editInline', 'edit']}
                            isEditable={true}
                            LinkComponent={Link}                            
                        />
                    </Box>                                    
                </>
            )}
        </>     
    );
}

import React, { useEffect, useRef, useState } from 'react';
import { useOutletContext, Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { GenericRelatedModelList } from 'drf-react-by-schema';
import dayjs from 'dayjs';

import '../styles/globals.css';
import '../styles/main.css';
import '../styles/home.css';
import DialogBulkActions from 'src/components/DialogBulkActions';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { pessoaCustomFieldFormLayouts } from '../utils/utils';
import HospedagemColumnOperations from 'src/components/HospedagemColumnOperations';

export default function Portaria() {
    /**
     * @type object
     */
    const { pageBasics, getRawData } = useOutletContext();
    const minWidthFields = { pessoa: 250, atividade: 200, vaga: 200 };
    const indexField = 'pessoa';

    const [defaultValues, setDefaultValues] = useState({
        entrada_prevista: null,
        saida_prevista: null,
    });

    const [actionsDialogLoading, setActionsDialogLoading] = useState(false);
    const [actionsDialogOpen, setActionsDialogOpen] = useState(null);
    const [actionsDialogData, setActionsDialogData] = useState([]);
    const [devolveuCopo, setDevolveuCopo] = useState({});
    const [devolveuChave, setDevolveuChave] = useState({});
    const [recebeuChave, setRecebeuChave] = useState({});
    const [recebeuCopo, setRecebeuCopo] = useState({});
    const bulkUpdateData = useRef(null);

    const customFieldFormLayouts = pessoaCustomFieldFormLayouts();

    const customColumnOperations = HospedagemColumnOperations;

    const handleAtividadeId = (event) => {
        sessionStorage.setItem('atividade_id', event.target.value);
        setAtividadeId(event.target.value);
    };
    const [atividade_id, setAtividadeId] = useState(sessionStorage.getItem('atividade_id'));
    const [atividades, setAtividades] = useState(null);


    const columnFieldsCheckin = [
        'pessoa',        
        'vaga',
        'observacao_pernoite',
        'tipo_de_participacao',
        'entrada_prevista',
        'saida_prevista',        
        'local_partida',
        'data_hora_partida',
        'complemento_partida',
        'pessoa_idiomas',        
    ];

    const columnFieldsCheckout = [
        'pessoa',        
        'vaga',
        'observacao_pernoite',
        'tipo_de_participacao',       
        'saida_prevista',
        'saida',
        'recebeu_chave',
        'recebeu_copo',
    ];

    const columnFieldsGone = [
        'pessoa',        
        'vaga',
        'observacao_pernoite',
        'tipo_de_participacao',               
        'saida',
        'devolveu_chave',
        'devolveu_copo',
    ];

    const onSelectActions = [
        {
            key: 'checkIn',
            title: 'Check In',
            applyButtonLabel: 'Fazer Check In',
            action: (selectionModel, updateData) => {
                setActionsDialogData(selectionModel);
                bulkUpdateData.current = updateData;
                setActionsDialogOpen(0);
                setActionsDialogLoading(false);
            },
        },        
        {
            key: 'checkOut',
            title: 'Check Out',
            applyButtonLabel: 'Fazer Check Out',
            action: (selectionModel, updateData) => {
                setActionsDialogData(selectionModel);
                bulkUpdateData.current = updateData;
                setActionsDialogOpen(1);
                setActionsDialogLoading(false);
            },
        },
        {
            key: 'bulkDelete',
            title: 'Apagar hospedagen(s)',
            action: 'bulkDelete',
        },
        {
            key: 'bulkRemoveVagas',
            title: 'Limpar vagas das hospedagens',
            applyButtonLabel: 'Limpar vagas',
            action: (selectionModel, updateData) => {
                setActionsDialogData(selectionModel);
                bulkUpdateData.current = updateData;
                setActionsDialogOpen(2);
                setActionsDialogLoading(false);
            },
        },
    ];

    const handleActionsDialogSubmit = async () => {
        setActionsDialogLoading(true);
        if (!bulkUpdateData.current || actionsDialogOpen === null) {
            return;
        }
        const now = dayjs().toISOString();
        let newData = null;
        switch (onSelectActions[actionsDialogOpen].key) {
            case 'checkOut':
                newData = actionsDialogData.map((hospedagem) => {
                    return {
                        ...hospedagem,
                        saida: now,
                        devolveu_chave:
                            hospedagem.id in devolveuChave ? devolveuChave[hospedagem.id] : false,
                        devolveu_copo:
                            hospedagem.id in devolveuCopo ? devolveuCopo[hospedagem.id] : false,
                    };
                });
                break;
            case 'checkIn':
                newData = actionsDialogData.map((hospedagem) => {
                    return {
                        ...hospedagem,
                        entrada: now,
                        recebeu_chave:
                            hospedagem.id in recebeuChave ? recebeuChave[hospedagem.id] : false,
                        recebeu_copo:
                            hospedagem.id in recebeuCopo ? recebeuCopo[hospedagem.id] : false,
                    };
                });
                break;
            case 'bulkRemoveVagas':
                newData = actionsDialogData.map((hospedagem) => {
                    return {
                        ...hospedagem,
                        vaga: null,
                    };
                });
                break;
        }
        if (newData) {
            await bulkUpdateData.current(newData);
        }
        setActionsDialogOpen(null);
    };    

    const getAtividades = async () => {
        const atividades = await getRawData(`atividade/?modo=ultimas_e_proximas`);
        setAtividades(atividades);
    };

    useEffect(() => {
        getAtividades();
    }, []);
    

    const SelectComponent = () => (
        <FormControl sx={{ m: 1, minWidth: 400 }}>
            <InputLabel id="demo-simple-select-label">Selecione uma atividade</InputLabel>
            <Select
                labelId="atividade-select-label"
                id="atividade-select"
                value={atividade_id}
                label="Atividade"
                onChange={handleAtividadeId}
            >
                {
                    atividades.results.map((value, idx) => (
                        <MenuItem value={value.id}>{value.label}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>        
    )

    if(!atividades){
        return <></>
    }

    if(!atividade_id){
        return <>
            {atividades && 
            <Box className="grid_wrapper">
                <SelectComponent />
            </Box>}
        </>
    }
    
    return (
        <>
            <Typography variant="h1" sx={{ mb: 3 }}>
                Portaria
            </Typography>            
            <Box className="grid_wrapper">
                <SelectComponent />
            </Box>            
            <Box className="grid_wrapper">
                <GenericRelatedModelList
                    model="atividade"
                    id={atividade_id}
                    relatedModel="hospedagem"
                    label="Entradas"
                    columnFields={columnFieldsCheckin}
                    creatableFields={[]}
                    disabledFields={[]}
                    hiddenFields={[]}
                    minWidthFields={minWidthFields}
                    indexField={indexField}
                    hasBulkSelect={true}
                    onSelectActions={onSelectActions}
                    sumRows={null}
                    customColumnOperations={customColumnOperations}
                    reloadAfterRowUpdate={true}
                    isAutoHeight={false}
                    tableAutoHeight={false}
                    indexFieldBasePath={'/hospedagem/'}
                    indexFieldViewBasePath={'/hospedagem/detalhe/'}
                    paginationMode="server"
                    actions={['editInline', 'edit', 'view']}
                    LinkComponent={Link}
                    defaultValues={defaultValues}
                    customFieldFormLayouts={customFieldFormLayouts}                    
                    queryParams={[`portaria=checkin`]}
                />
            </Box>
            <Box className="grid_wrapper">
                <GenericRelatedModelList
                    model="atividade"
                    id={atividade_id}
                    relatedModel="hospedagem"
                    label="Saídas"
                    columnFields={columnFieldsCheckout}
                    creatableFields={[]}
                    disabledFields={[]}
                    hiddenFields={[]}
                    minWidthFields={minWidthFields}
                    indexField={indexField}
                    hasBulkSelect={true}
                    onSelectActions={onSelectActions}
                    sumRows={null}
                    customColumnOperations={customColumnOperations}
                    reloadAfterRowUpdate={true}
                    isAutoHeight={false}
                    tableAutoHeight={false}
                    indexFieldBasePath={'/hospedagem/'}
                    indexFieldViewBasePath={'/hospedagem/detalhe/'}
                    paginationMode="server"
                    actions={['editInline', 'edit', 'view']}
                    LinkComponent={Link}
                    defaultValues={defaultValues}
                    customFieldFormLayouts={customFieldFormLayouts}
                    queryParams={[`portaria=checkout`]}
                />
            </Box>
            <Box className="grid_wrapper">
                <GenericRelatedModelList
                    model="atividade"
                    id={atividade_id}
                    relatedModel="hospedagem"
                    label="Quartos liberados"
                    columnFields={columnFieldsGone}
                    creatableFields={[]}
                    disabledFields={[]}
                    hiddenFields={[]}
                    minWidthFields={minWidthFields}
                    indexField={indexField}
                    hasBulkSelect={true}
                    onSelectActions={onSelectActions}
                    sumRows={null}
                    customColumnOperations={customColumnOperations}
                    reloadAfterRowUpdate={true}
                    isAutoHeight={false}
                    tableAutoHeight={false}
                    indexFieldBasePath={'/hospedagem/'}
                    indexFieldViewBasePath={'/hospedagem/detalhe/'}
                    paginationMode="server"
                    actions={['editInline', 'edit', 'view']}
                    LinkComponent={Link}
                    defaultValues={defaultValues}
                    customFieldFormLayouts={customFieldFormLayouts}
                    queryParams={[`portaria=gone`]}
                />
            </Box>            
            <DialogBulkActions
                onSelectActions={onSelectActions}
                actionsDialogOpen={actionsDialogOpen}
                setActionsDialogOpen={setActionsDialogOpen}
                actionsDialogLoading={actionsDialogLoading}
                actionsDialogData={actionsDialogData}
                handleActionsDialogSubmit={handleActionsDialogSubmit}
                setDevolveuChave={setDevolveuChave}
                setDevolveuCopo={setDevolveuCopo}
                setRecebeuChave={setRecebeuChave}
                setRecebeuCopo={setRecebeuCopo}
            />                  
            {/* <PortariaChegadasPrevistas
                dia={dia}
                minWidthFields={minWidthFields}
                indexField={indexField}
                sumRows={sumRows}
                customColumnOperations={customColumnOperations}
            />
            <PortariaSaidasPrevistas
                dia={dia}
                minWidthFields={minWidthFields}
                indexField={indexField}
                sumRows={sumRows}
                customColumnOperations={customColumnOperations}
            /> */}
        </>
    );
}

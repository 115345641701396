import React, { useEffect, useState } from 'react';
import { useParams, useOutletContext, Navigate, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { DetailBySchema } from 'drf-react-by-schema';
import { Layout } from 'src/styles';

export default function HospedagemDetail() {
    const model = 'hospedagem';
    const basePath = '/';
    const objTitleField = 'id';
    const [fieldsLayout, setFieldsLayout] = useState([
        {
            rows: [
                'pessoa',
                'atividade',
                'vaga',
                ['entrada_prevista', 'saida_prevista'],
                'tipo_de_participacao',
                ['carro', 'placa_do_carro', 'descricao_do_carro'],
                'observacoes',
            ],
        },
    ]);
    const params = useParams();
    /**
     * @type object
     */
    const { loadSinglePageData, pageBasics, pageForm, optionsAC, setOptionsAC } =
        useOutletContext();

    const loadAllData = () => {
        const defaults = {};
        loadSinglePageData({
            model,
            objTitleField,
            objId: params.objId,
            defaults,
            basePath,
        });
    };

    useEffect(() => {
        loadAllData();
    }, [params.objId]);

    useEffect(() => {
        if (pageForm.forceReload) {
            loadAllData();
        }
    }, [pageForm.forceReload]);

    const editLink = basePath + model + '/' + params.objId;

    return (
        <>
            {pageForm.schema === false && <Navigate to="/login" replace />}
            {pageBasics.model === model && pageForm.initialValues && (
                <Box>
                    <Typography variant="h1">
                        Hospedagem -
                        {pageForm.initialValues['pessoa']
                            ? ' ' + pageForm.initialValues['pessoa'].label
                            : ' Reserva'}
                    </Typography>
                    <DetailBySchema
                        values={pageForm.initialValues}
                        schema={pageForm.schema}
                        editLink={editLink}
                        editLabel={'Editar'}
                        fieldsLayout={fieldsLayout}
                        optionsAC={optionsAC}
                        setOptionsAC={setOptionsAC}
                        sxRow={{ marginTop: 2, backgroundColor: '#eee' }}
                        sxRowMultiple={{ ...Layout.flexRow, backgroundColor: '#eee' }}
                        sxField={{ marginTop: 2, padding: 2 }}
                        sxLabel={{ marginTop: 0, fontSize: 10, textTransform: 'uppercase' }}
                        sxValue={{ marginBottom: 1, fontSize: 20 }}
                        sxValueList={{ margin: 0 }}
                        sxValueListItem={{ color: '#000', margin: 0 }}
                        sxValueListItemText={{ color: '#000' }}
                    />
                </Box>
            )}
        </>
    );
}

import React, { useEffect, useState } from 'react';

import GenericPageList from '../pages/GenericPageList';
import { useLocation } from 'react-router-dom';

export default function GenericSettingsList() {
    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const model = pathParts[2] || null;
    const indexField = 'nome';
    const defaultHiddenFields = ['id', 'label', 'slug'];
    const [hiddenFields, setHiddenFields] = useState(null);

    useEffect(() => {
        switch (model) {
            case 'vaga':
                setHiddenFields([
                    ...defaultHiddenFields,
                    'pessoa',
                    'atividade',
                    'entrada_prevista',
                    'saida_prevista',
                ]);
                break;
            case 'refeicao':
                setHiddenFields([...defaultHiddenFields, 'n_refeicoes']);
                break;
            default:
                setHiddenFields(defaultHiddenFields);
        }
    }, [model]);

    const sumRows = {
        rows: [],
        severity: 'info',
    };

    if (hiddenFields === null) {
        return <></>;
    }

    return (
        <GenericPageList
            hiddenFields={hiddenFields}
            indexField={indexField}
            sumRows={sumRows}
            hasHeader={true}
        />
    );
}

import React from 'react';
import TextField from '@mui/material/TextField';

export function Idade({ onValueChange, value }) {
    return (
        <TextField
            id="idade"
            key="idade"
            label="Idade"
            margin="normal"
            fullWidth
            value={value}
            onChange={onValueChange}
        />
    );
}

import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AuthRoute from './components/AuthRoute';
import * as Page from './pages';
import { DRFReactBySchemaProvider, slugToCamelCase } from 'drf-react-by-schema';
import theme from './styles/theme';
import CONSTANTS from './constants';
import PAGES from './pageTree';
import NewUsuariaForm from './components/NewUsuariaForm';

export default function App() {
    return (
        <DRFReactBySchemaProvider
            serverEndPoint={{
                url: CONSTANTS.API.url,
                apiTokenUrl: CONSTANTS.API.apiTokenUrl,
                signUp: CONSTANTS.API.signUp,
            }}
            theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route element={<AuthRoute />}>
                        <Route path="/" element={<Page.PageContentHandler />}>
                            {Object.entries(PAGES).map((entry) => {
                                const [key, pageInfo] = entry;
                                if (pageInfo.skipRouter) {
                                    return <React.Fragment key={key}></React.Fragment>;
                                }
                                const componentName = slugToCamelCase(`_${key}`);
                                const PageList = Page[`${componentName}List`];
                                const PageForm = Page[componentName];
                                const pageDetailKey = `${componentName}Detail`;
                                const PageDetail =
                                    pageDetailKey in Page ? Page[pageDetailKey] : null;
                                return (
                                    <Route key={key} path={key} element={<PageList />}>
                                        {PageForm && <Route path=":objId" element={<PageForm />} />}
                                        {PageDetail && (
                                            <Route path="detalhe/:objId" element={<PageDetail />} />
                                        )}
                                    </Route>
                                );
                            })}
                            <Route path="signup" element={<NewUsuariaForm />} />
                            <Route path="settings" element={<Page.GenericSettingsIndex />}>
                                <Route path=":model" element={<Page.GenericSettingsList />}>
                                    <Route path=":objId" element={<Page.GenericSettings />} />
                                </Route>
                            </Route>
                            <Route
                                path="*"
                                element={
                                    <main>
                                        <p>Página não encontrada!</p>
                                    </main>
                                }
                            />
                            <Route index element={<Page.Home />} />
                            <Route path="/alojamento" element={<Page.Alojamento />} />
                            <Route path="/cozinha" element={<Page.Cozinha />} />
                            <Route path="/portaria" element={<Page.Portaria />} />
                            <Route path="/transporte" element={<Page.Transporte />} />
                            <Route path="/ciranda" element={<Page.Ciranda />} />
                            <Route path="/equipe" element={<Page.EquipeList />} />
                        </Route>
                    </Route>
                    <Route path="/login" element={<Page.Login />} />
                </Routes>
            </BrowserRouter>
        </DRFReactBySchemaProvider>
    );
}

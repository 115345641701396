import React from 'react';
import Box from '@mui/material/Box';

export default function RefeicaoBox({ label: refeicaoLabel, nRefeicoes }) {
    if (!nRefeicoes) {
        return <></>;
    }
    return (
        <Box>
            <h3>{refeicaoLabel}</h3>
            <p>{nRefeicoes[0]}</p>
            <ul>
                {Object.keys(nRefeicoes[1])
                    .filter((v) => nRefeicoes[1][v] !== 0)
                    .map((value, idx) => (
                        <li key={idx}>
                            {value}:{nRefeicoes[1][value]}
                        </li>
                    ))}
            </ul>
        </Box>
    );
}

import React, { useEffect, useState } from 'react';
import { useParams, useOutletContext, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { isTmpId, FormBySchema } from 'drf-react-by-schema';
import { pessoaCustomFieldFormLayouts } from '../utils/utils';
import FormButtons from '../components/FormButtons';

export default function Atividade() {
    const model = 'atividade';
    const basePath = '/';
    const objTitleField = 'nome';
    const [fieldsLayout, setFieldsLayout] = useState([
        {
            rows: [
                ['nome','status'],
                'organizacoes',
                ['categoria_de_atividade', 'tipo_de_atividade'],
                ['inicio', 'fim'],
                ['n_mulheres', 'n_homens'],
                'contatos',
            ],
        },
    ]);
    const [hiddenFields, setHiddenFields] = useState([]);
    const params = useParams();
    /**
     * @type object
     */
    const {
        loadSinglePageData,
        onSubmit,
        onDeleteModel,
        pageBasics,
        pageForm,
        optionsAC,
        setOptionsAC,
    } = useOutletContext();
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        setValue,
        formState: { errors, isDirty },
        register,
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(pageForm.validationSchema),
    });

    const loadAllData = () => {
        const defaults = {};
        loadSinglePageData({
            model,
            objTitleField,
            objId: params.objId,
            defaults,
            basePath,
        }).then((values) => {
            reset(values);
        });
    };

    useEffect(() => {
        loadAllData();
    }, [params.objId]);

    useEffect(() => {
        if (pageForm.forceReload) {
            loadAllData();
        }
    }, [pageForm.forceReload]);

    const formButtonProps = {
        objId: params.objId || null,
        onDeleteModel,
        formDisabled: !isDirty,
        title: isTmpId(pageBasics.id) ? 'Nova Atividade' : 'Editar Atividade',
    };

    const customFieldFormLayouts = pessoaCustomFieldFormLayouts();
    customFieldFormLayouts['contatos'] = customFieldFormLayouts['pessoa'];
    delete(customFieldFormLayouts['pessoa']);

    return (
        <>
            {pageForm.schema === false && <Navigate to="/login" replace />}
            {pageBasics.model === model && pageForm.initialValues && control && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormButtons {...formButtonProps} />
                    <Box>
                        <FormBySchema
                            fieldsLayout={fieldsLayout}
                            customFieldFormLayouts={customFieldFormLayouts}
                            hiddenFields={hiddenFields}
                            control={control}
                            register={register}
                            errors={errors}
                            schema={pageForm.schema}
                            optionsAC={optionsAC}
                            setOptionsAC={setOptionsAC}
                            forceReload={pageForm.forceReload}
                            getValues={getValues}
                            setValue={setValue}
                        />
                    </Box>
                    <FormButtons {...formButtonProps} bottom={true} />
                </form>
            )}
        </>
    );
}

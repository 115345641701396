import React, { useEffect, useState } from 'react';
import { useOutletContext, Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useAPIWrapper, GenericModelList } from 'drf-react-by-schema';
import MetabaseAppEmbed from '../components/MetabaseAppEmbed';

import '../styles/globals.css';
import '../styles/main.css';
import '../styles/home.css';

export default function Home() {
    /**
     * @type object
     */
    const { pageBasics } = useOutletContext();
    const { getRawData } = useAPIWrapper();

    const columnFieldsAtividades = [
        'nome',
        'inicio',
        'fim',
        'organizacao',
        'tipo_de_atividade',
        'total_previsto',
        'n_hospedagens',
    ];

    const minWidthFieldsAtividades = {
        nome: 380,
        organizacao: 380,
        total_previsto: 150,
        n_hospedagens: 105,
    };

    const indexField = 'pessoa';
    const sumRows = {
        rows: [],
    };
    const customColumnOperations = null;

    const [resumo_do_dia, setResumoDoDia] = useState(null);

    const getResumoDoDiaAsync = async () => {
        const newResumoDoDia = await getRawData(
            `resumo_do_dia/?dia=${pageBasics.day.format('YYYY-MM-DD')}`
        );
        if (newResumoDoDia) {
            setResumoDoDia(newResumoDoDia);
            return;
        }
        console.log('erro ao tentar baixar ResumoDoDia!');
    };

    useEffect(() => {
        getResumoDoDiaAsync();
    }, [pageBasics.day]);

    if (!resumo_do_dia) {
        return <></>;
    }

    var payload = {
        resource: { dashboard: 1 },
        params: {},
        exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
    };

    return (
        <>
            <Typography variant="h1" sx={{ mb: 3 }}>
                Resumo do Dia
            </Typography>
            <Box
                className="grid_wrapper"
                sx={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)' }}>
                {resumo_do_dia.map((value, idx) => (
                    <Box key={idx}>
                        <Typography sx={{ fontSize: 60, textAlign: 'center' }}>
                            {value[1]}
                        </Typography>
                        <Typography sx={{ textAlign: 'center' }}>{value[0]}</Typography>
                    </Box>
                ))}
            </Box>
            <Box className="grid_wrapper">
                <Typography variant="h2">Atividades do Dia</Typography>
                <GenericModelList
                    columnFields={columnFieldsAtividades}
                    hiddenFields={[]}
                    minWidthFields={minWidthFieldsAtividades}
                    indexField={indexField}
                    sumRows={sumRows}
                    customColumnOperations={customColumnOperations}
                    isAutoHeight={true}
                    tableAutoHeight={true}
                    customLinkDestination={null}
                    model={'atividade'}
                    indexFieldBasePath={`/atividade/`}
                    forceReload={false}
                    LinkComponent={Link}
                    hasHeader={false}
                    paginationMode="server"
                    queryParams={[`dia=${pageBasics.day.format('YYYY-MM-DD')}`]}
                    indexFieldViewBasePath={'/atividade/detalhe/'}
                    actions={['edit', 'view']}
                    isEditable={true}
                    hideFooterComponent={true}
                    hideToolbarComponent={true}
                />
            </Box>
            {payload.resource.dashboard ? (
                <Box className="grid_wrapper">
                    <Typography variant="h2">Estatísticas</Typography>
                    <MetabaseAppEmbed
                        title="Relatórios"
                        className="metabaseDashboard"
                        payload={payload}
                        onFrame={(frameLoaded) => {
                            console.log(frameLoaded);
                            // TODO: Should we add loading component for frame embed loading?
                        }}
                        titled={false}
                    />
                </Box>
            ) : (
                <Box
                    className="grid_wrapper"
                    sx={{
                        height: 100,
                        backgroundColor: '#fff',
                    }}
                />
            )}
        </>
    );
}

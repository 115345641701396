import React, { useState } from 'react';
import { GenericModelList, SelectEditInputCell } from 'drf-react-by-schema';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import KeyIcon from '@mui/icons-material/Key';
import { pessoaCustomFieldFormLayouts } from '../utils/utils';

export default function QuartoRelatedHospedagem({
    quartoId,
    label,
    pageBasics,
    optionsAC,
    ...props
}) {
    const [forceReload, setForceReload] = useState(false);
    // Specific infos:
    const model = 'hospedagem';
    const columnFields = [
        'id',
        'vaga',
        'pessoa',
        'atividade',
        'entrada_prevista',
        'saida_prevista',
        'pessoa_idiomas',
        'recebeu_chave'
    ];

    const customFieldFormLayouts = pessoaCustomFieldFormLayouts();

    const creatableFields = [];
    const disabledFields = ['atividade', 'entrada_prevista', 'saida_prevista'];
    const minWidthFields = {
        vaga: 260,
        atividade: 200,
        entrada_prevista: 150,
        saida_prevista: 150,
    };
    const indexField = 'pessoa';
    const addExistingModel = '';
    const onProcessRow = null;
    const sumRows = null;
    const hiddenFields = ['id', 'vagas_disponiveis'];
    const hideFooterComponent = true;
    const hideToolbarComponent = false;
    const tableAutoHeight = true;
    const customColumnOperations = (column) => {
        if (column.field === 'vaga') {
            column.renderEditCell = (params) => {
                if (params.row.vagas_disponiveis) {
                    return (
                        <SelectEditInputCell
                            {...params}
                            column={column}
                            type="field"
                            optionsAC={{ vaga: params.row.vagas_disponiveis }}
                            isIndexField={false}
                            onEditModel={null}
                        />
                    );
                } else {
                    return (
                        <Box>
                            <i>(Salve e volte para definir vaga)</i>
                        </Box>
                    );
                }
            };
        }
        if (column.field === 'pessoa') {
            column.renderCell = (params) => {
                if (params.formattedValue == '' && params.row.genero) {
                    return <p id={params.row.id}>Reserva - {params.row.genero.display_name}</p>;
                } else if (params.formattedValue == '') {
                    return <p id={params.row.id}>Reserva</p>;
                } else {
                    return <p id={params.row.id}>{params.formattedValue}</p>;
                }
            };
        }   
        if (column.field === 'recebeu_chave') {
            column.renderCell = (params) => {
                if(params.value == true){
                    return <p><KeyIcon /></p>
                } else {
                    return <p></p>
                }
            }
        }
        

        return column;
    };
    const onSelectActions = [
        {
            title: 'Apagar hospedagens',
            action: 'bulkDelete',
        },
    ];

    return (
        <>
            <GenericModelList
                columnFields={columnFields}
                indexField={indexField}
                hiddenFields={hiddenFields}
                creatableFields={creatableFields}
                disabledFields={disabledFields}
                LinkComponent={Link}
                indexFieldBasePath={'/hospedagem/'}
                indexFieldViewBasePath={'/hospedagem/detalhe/'}
                actions={['editInline', 'edit', 'view']}
                optionsAC={optionsAC}
                model={model}
                queryParams={[
                    `quarto_id=${quartoId}`,
                    `dia=${pageBasics.day.format('YYYY-MM-DD')}`,
                ]}
                forceReload={forceReload}
                hasHeader={false}
                paginationMode="client"
                hasBulkSelect={true}
                onSelectActions={onSelectActions}
                isEditable={true}
                minWidthFields={minWidthFields}
                sumRows={sumRows}
                onProcessRow={onProcessRow}
                addExistingModel={addExistingModel}
                hideFooterComponent={hideFooterComponent}
                hideToolbarComponent={hideToolbarComponent}
                tableAutoHeight={tableAutoHeight}
                disableScreenLoading={true}
                customColumnOperations={customColumnOperations}
                customFieldFormLayouts={customFieldFormLayouts}
            />
        </>
    );
}

import React, { useEffect, useState } from 'react';
import { useLocation, useOutletContext, Outlet, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';

import SidebarSettings from '../components/SidebarSettings';
import PAGES from 'src/pageTree';
import { useAPIWrapper } from 'drf-react-by-schema';

const shouldRemove = [
    'usuaria_groups',
    'usuaria_user_permissions',
    'pessoa_restricao_alimentar',
    'uf',
    'municipio',
    'pessoa',
    'usuaria',
    'atividade_contatos',
    'atividade_organizacoes',
    'pessoa_idiomas',
];

export default function GenericSettingsIndex() {
    const { getAllModels } = useAPIWrapper();
    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const page = pathParts[1] || null;
    const model = pathParts[2] || null;
    /**
     * @type object
     */
    const outletContext = useOutletContext();
    const context = {
        ...outletContext,
        model,
        indexFieldBasePath: `/${page}/${model}/`,
    };
    const { usuaria } = context;
    const [list, setList] = useState([]);

    useEffect(() => {
        getAllModels().then((models) => {
            const removeFromList = [];
            for (const [key, menu] of Object.entries(PAGES)) {
                removeFromList.push(key);
            }
            const list = models.filter(
                (model) =>
                    !removeFromList.includes(model.model_name) &&
                    !shouldRemove.includes(model.model_name)
            );
            setList(list);
        });
    }, [location]);

    return (
        <>
            {list.length > 0 && <SidebarSettings items={list} model={model} />}
            <Box sx={{ paddingLeft: '320px' }}>
                <Outlet context={context} />
            </Box>
        </>
    );
}

import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Outlet, useNavigate, useLocation, useParams, Navigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useAPIWrapper, isTmpId } from 'drf-react-by-schema';
import dayjs from 'dayjs';

import { Layout } from '../styles';
import { reducer, getPageTitle } from '../utils/utils';
import PAGES from '../pageTree';

import TopBar from '../components/TopBar';
import SidebarMenu from '../components/SidebarMenu';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

function PageContentHandler() {
    const {
        usuaria,
        handleLoading,
        optionsACState,
        pageFormState,
        onTriggerSnackBar,
        setDialog,
        loadSinglePageData,
        onEditRelatedModelSave,
        onDeleteRelatedModel,
        onDeleteModel,
        onSubmit,
        getAutoComplete,
        getRawData,
    } = useAPIWrapper();
    const [pageForm, setPageForm] = pageFormState;
    const [optionsAC, setOptionsAC] = optionsACState;
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const initialPageBasics = {
        model: '',
        id: '',
        day: dayjs(),
        defaultFilter: null,
    };

    /**
     * @type object
     */
    const [pageBasics, setPageBasics] = useReducer(reducer, initialPageBasics);

    useEffect(() => {
        const title = getPageTitle(location, params.objId);
        if (location.pathname.split('/')[1] === 'settings') {
            initialPageBasics.isSettings = true;
        }
        document.title = title;
        const newDay = dayjs(sessionStorage.getItem('day')).isValid()
            ? dayjs(sessionStorage.getItem('day'))
            : dayjs();
        setPageBasics({
            ...initialPageBasics,
            day: newDay,
            id: params.objId || '',
        });

        // TODO: Check if the following pageForm initialization is needed here or if it was enough in APIWrapper provider
        setPageForm({
            id: '',
            schema: null,
            initialValues: null,
            validationSchema: null,
            forceReload: false,
        });
    }, [location]);

    const localLoadSinglePageData = async (params) => {
        const values = await loadSinglePageData(params);
        setPageBasics({
            model: params.model,
            pageTitle: values[params.objTitleField],
            id: isTmpId(params.objId) || params.objId === 'novo' ? null : params.objId,
            basePath: params.basePath,
            formPath: params.formPath,
        });
        return values;
    };

    const localOnSubmit = async (data, e) => {
        const response = await onSubmit(pageBasics.model, pageBasics.id, data, e);

        if (response !== false) {
            const buttonType = e.nativeEvent.submitter.name || 'exitOnSave';
            const basePath = `${pageBasics.basePath}${pageBasics.formPath || pageBasics.model}`;

            handleLoading(false);

            if (buttonType === 'exitOnSave') {
                // navigate(basePath, { state: 'reload' });
                navigate(-1);
                return;
            }

            if (buttonType === 'createNewOnSave') {
                navigate(`${basePath}/novo`);
                setPageForm({ forceReload: true });
                return;
            }

            if (isTmpId(pageBasics.id)) {
                navigate(`${basePath}/${response}`);
            }

            setPageForm({ forceReload: true });
        }

        return response;
    };

    const localOnDeleteModel = () => {
        onDeleteModel(pageBasics.model, pageBasics.id, () => {
            handleLoading(false);
            navigate(`/${pageBasics.model}`, { state: 'forceReload', replace: true });
        });
    };

    const localOnEditRelatedModelSave = async (params) => {
        const response = await onEditRelatedModelSave({
            model: pageBasics.model,
            id: pageBasics.id,
            ...params,
        });
        return response;
    };

    const localOnDeleteRelatedModel = async (params) => {
        const response = await onDeleteRelatedModel({
            model: pageBasics.model,
            id: pageBasics.id,
            ...params,
        });
        return response;
    };

    const pageContext = {
        usuaria,
        onSubmit: localOnSubmit,
        loadSinglePageData: localLoadSinglePageData,
        handleLoading,
        pageBasics,
        pageForm,
        optionsAC,
        setOptionsAC,
        onDeleteModel: localOnDeleteModel,
        onEditRelatedModelSave: localOnEditRelatedModelSave,
        onDeleteRelatedModel: localOnDeleteRelatedModel,
        onTriggerSnackBar,
        setDialog,
        PAGES,
        getAutoComplete,
        getRawData,
    };

    const changeDayHandler = (newDay) => {
        sessionStorage.setItem('day', newDay);
        setPageBasics({ day: newDay });
    };

    return (
        <>
            {usuaria && !usuaria.erro && (
                <Box sx={{ display: 'flex', mb: 3 }}>
                    <TopBar
                        usuaria={usuaria}
                        pageBasics={pageBasics}
                        changeDayHandler={changeDayHandler}
                        location={location}
                    />
                    <Box component="main" sx={Layout.main}>
                        {pageBasics.isSettings !== true && <SidebarMenu />}
                        <Box sx={Layout.content}>
                            <DrawerHeader />
                            <Outlet context={pageContext} />
                        </Box>
                    </Box>
                </Box>
            )}
            {usuaria && usuaria.erro && <Navigate to="/login" replace />}
        </>
    );
}

export default React.memo(PageContentHandler);

PageContentHandler.propTypes = {
    setLoading: PropTypes.func,
    handleLoading: PropTypes.func,
    setSnackBar: PropTypes.func,
    setDialog: PropTypes.func,
};

import React from 'react';

export default function QuartoTabHead({
    nome,
    escadaBool,
    acessibilidadeBool,
    vagas_vazias,
    genero,
}) {
    let acessibilidade = '';
    let escada = '';
    if (escadaBool) {
        escada = '- Piso Superior';
    }
    if (acessibilidadeBool) {
        acessibilidade = ' - Acessibilidade';
    }
    if (genero) {
        genero = '- ' + genero;
    }
    if (vagas_vazias == 0) {
        vagas_vazias = 'Lotado';
    } else if (vagas_vazias == 1) {
        vagas_vazias = vagas_vazias + ' vaga';
    } else {
        vagas_vazias = vagas_vazias + ' vagas';
    }

    return (
        <p>
            {nome} {escada} {acessibilidade} - {vagas_vazias} {genero}
        </p>
    );
}

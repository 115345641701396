import React, { useEffect, useRef, useState } from 'react';
import { useParams, useOutletContext, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { isTmpId, FormBySchema } from 'drf-react-by-schema';
import dayjs from 'dayjs';
import { pessoaCustomFieldFormLayouts } from '../utils/utils';
import FormButtons from '../components/FormButtons';

export default function Hospedagem() {
    const model = 'hospedagem';
    const basePath = '/';
    const objTitleField = 'pessoa';
    const params = useParams();
    const waitForLoading = useRef(false);
    /**
     * @type object
     */
    const {
        loadSinglePageData,
        onSubmit,
        onDeleteModel,
        pageBasics,
        pageForm,
        optionsAC,
        setOptionsAC,
        getAutoComplete,
        getRawData,
    } = useOutletContext();
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        setValue,
        formState: { errors, isDirty },
        watch,
        register,
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(pageForm.validationSchema),
    });

    const pessoa = watch('pessoa');
    const atividade = watch('atividade');
    const atividadeTouched = useRef(false);
    const entradaPrevista = watch('entrada_prevista');
    const saidaPrevista = watch('saida_prevista');
    const vaga = watch('vaga');
    const vemDeCarro = watch('carro');
    const recebeuChave = watch('recebeu_chave');
    const recebeuCopo = watch('recebeu_copo');

    const [idade, setIdade] = useState(null);

    const loadAllData = async () => {
        const defaults = {};
        const values = await loadSinglePageData({
            model,
            objTitleField,
            objId: params.objId,
            defaults,
            basePath,
        });
        reset(values);
        const promises = [];
        const fields = ['pessoa', 'atividade', 'tipo_de_participacao', 'vaga'];
        fields.map((field) => {
            promises.push(
                field === 'vaga'
                    ? getVagasDisponiveis(
                          values.entrada_prevista,
                          values.saida_prevista,
                          values.vaga
                      )
                    : getAutoComplete(field)
            );
        });
        const results = await Promise.all(promises);
        const newOptionsAC = {};
        results.map((result, index) => {
            if (result) {
                newOptionsAC[fields[index]] = result;
            }
        });
        setOptionsAC(newOptionsAC);
    };

    const customOnSubmit = (data, e) => {
        onSubmit(data, e).then(() => {
            setTimeout(() => {
                getVagasDisponiveis(entradaPrevista, saidaPrevista, getValues('vaga')).then(
                    (newVagas) => {
                        if (newVagas) {
                            setOptionsAC({ vaga: newVagas });
                        }
                    }
                );
            }, 1000);
        });
    };

    const getVagasDisponiveis = async (newEntradaPrevista, newSaidaPrevista, vaga) => {
        if (
            newEntradaPrevista &&
            dayjs(newEntradaPrevista).isValid() &&
            newSaidaPrevista &&
            dayjs(newSaidaPrevista).isValid()
        ) {
            let newVagas = await getRawData(
                `vagas_disponiveis/?entrada_prevista=${dayjs(newEntradaPrevista).format(
                    'YYYY-MM-DD'
                )}&saida_prevista=${dayjs(newSaidaPrevista).format('YYYY-MM-DD')}`
            );
            if (!isTmpId(params.objId) && vaga) {
                newVagas = [vaga, ...newVagas];
            }
            return newVagas;
        }
        return null;
    };

    useEffect(() => {
        if (waitForLoading.current) {
            return;
        }
        if (!optionsAC) {
            waitForLoading.current = true;
            atividadeTouched.current = false;
            loadAllData().then(() => {
                waitForLoading.current = false;
            });
            return;
        }
        getVagasDisponiveis(entradaPrevista, saidaPrevista, getValues('vaga')).then((newVagas) => {
            if (newVagas) {
                setOptionsAC({ vaga: newVagas });
            }
        });
    }, [entradaPrevista, saidaPrevista]);

    useEffect(() => {
        waitForLoading.current = true;
        loadAllData().then(() => {
            waitForLoading.current = false;
        });
    }, [params.objId]);

    useEffect(() => {
        if (!vemDeCarro) {
            setValue('placa_do_carro', '');
            setValue('descricao_do_carro', '');
        }
    }, [vemDeCarro]);

    useEffect(() => {
        if (pessoa) {
            const regExp = /\(([0-9]+)\)/;
            const matches = pessoa.label.match(regExp);
            const newIdade = matches ? matches[1] : null;
            setIdade(newIdade);
        }
    }, [pessoa]);

    useEffect(() => {
        if (atividadeTouched.current && atividade) {
            atividadeTouched.current = false;
            if (atividade.inicio) {
                setValue('entrada_prevista', atividade.inicio);
            }
            if (atividade.fim) {
                setValue('saida_prevista', atividade.fim);
            }
        }
    }, [atividade]);

    useEffect(() => {
        return () => {
            waitForLoading.current = true;
        };
    }, []);

    const formButtonProps = {
        objId: params.objId || null,
        onDeleteModel,
        formDisabled: !isDirty,
        title: isTmpId(pageBasics.id) ? 'Nova Hospedagem' : 'Editar Hospedagem',
    };

    const fieldsLayout = [
        {
            title: 'Informações básicas',
            rows: [
                'pessoa',
                'genero',
                'atividade',
                ['entrada_prevista', 'saida_prevista', 'vaga'],
                'observacao_pernoite',
                'tipo_de_participacao',
                ['carro', 'placa_do_carro', 'descricao_do_carro'],
                'observacoes',
            ],
        },
        {
            title: 'Criança / Adolescente',
            rows: [['responsavel', 'demanda_ciranda']],
        },
        {
            title: 'Check-in/Check-out',
            rows: [
                'entrada',
                'recebeu_chave',
                'recebeu_copo',
                'saida',
                'devolveu_chave',
                'devolveu_copo',
            ],
        },
        {
            title: 'Logística',
            rows: [
                'local_chegada',
                'data_hora_chegada',
                'complemento_chegada',
                'local_partida',
                'data_hora_partida',
                'complemento_partida',
            ],
        },
    ];
    const customFieldFormLayouts = pessoaCustomFieldFormLayouts();

    const fieldsProps = {
        entrada_prevista: { sx: { width: 450 } },
        saida_prevista: { sx: { width: 450 } },
        atividade: {
            onValueChange: () => {
                atividadeTouched.current = true;
            },
        },
        carro: { sx: { width: 400, mr: 0 } },
        vaga: {
            disabled:
                !entradaPrevista ||
                !saidaPrevista ||
                (entradaPrevista &&
                    saidaPrevista &&
                    dayjs(entradaPrevista).format('YYYY-MM-DD') ==
                        dayjs(saidaPrevista).format('YYYY-MM-DD')),
        },
        observacao_pernoite: {
            disabled:
                !!vaga ||
                (entradaPrevista &&
                    saidaPrevista &&
                    dayjs(entradaPrevista).format('YYYY-MM-DD') ==
                        dayjs(saidaPrevista).format('YYYY-MM-DD')),
        },
        placa_do_carro: { disabled: !vemDeCarro },
        descricao_do_carro: { disabled: !vemDeCarro },
        devolveu_chave: { disabled: !recebeuChave },
        devolveu_copo: { disabled: !recebeuCopo },
        demanda_ciranda: {
            sx: { width: 250 },
            disabled: !idade || parseInt(idade) >= 18,
        },
        responsavel: {
            disabled: !idade || parseInt(idade) >= 18,
        },
    };

    if (waitForLoading.current) {
        return <></>;
    }

    return (
        <>
            {pageForm.schema === false && <Navigate to="/login" replace />}
            {pageBasics.model === model && pageForm.initialValues && control && (
                <form onSubmit={handleSubmit(customOnSubmit)}>
                    <FormButtons {...formButtonProps} />
                    <Box>
                        <FormBySchema
                            fieldsLayout={fieldsLayout}
                            fieldsProps={fieldsProps}
                            customFieldFormLayouts={customFieldFormLayouts}
                            control={control}
                            register={register}
                            errors={errors}
                            schema={pageForm.schema}
                            optionsAC={optionsAC}
                            setOptionsAC={setOptionsAC}
                            forceReload={pageForm.forceReload}
                            getValues={getValues}
                            setValue={setValue}
                        />
                    </Box>
                    <FormButtons {...formButtonProps} bottom={true} />
                </form>
            )}
        </>
    );
}

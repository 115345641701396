import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export function IdadeDetail({ value, sxField, sxLabel, sxValue }) {
    return (
        <Box sx={sxField}>
            <Typography variant="h5" sx={sxLabel}>
                Idade
            </Typography>
            <Typography variant="body2" sx={sxValue}>
                {value}
            </Typography>
        </Box>
    );
}

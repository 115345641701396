import React, { useEffect, useState } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import '../styles/globals.css';
import '../styles/home.css';
import { Layout } from 'src/styles';
import { useAPIWrapper } from 'drf-react-by-schema';

import BlocoTab from '../components/BlocoTab';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ pt: 3 }}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Alojamento() {
    /**
     * @type object
     */
    const { pageBasics } = useOutletContext();
    const { getAutoComplete } = useAPIWrapper();

    const [value, setValue] = React.useState(0);
    const [blocos, setBlocos] = useState(null);
    const location = useLocation();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getBlocosAsync = async () => {
        const bl = await getAutoComplete('bloco');
        setBlocos(bl);
    };

    useEffect(() => {
        getBlocosAsync();
    }, [location]);

    if (!blocos) {
        return <></>;
    }

    return (
        <>
            <Typography variant="h1" sx={{ mb: 3 }}>
                Alojamento
            </Typography>
            <Box className="grid_wrapper">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        {blocos.map((bloco, idx) => (
                            <Tab key={bloco.id} label={bloco.label} {...a11yProps(idx)} />
                        ))}
                    </Tabs>
                </Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    {blocos.map((bloco, idx) => (
                        <CustomTabPanel key={bloco.id} value={value} index={idx}>
                            <BlocoTab
                                blocoId={bloco.id}
                                pageBasics={pageBasics}
                                // onEditModel={onEditModel}
                            />
                        </CustomTabPanel>
                    ))}
                </Box>
            </Box>
        </>
    );
}

import React, { useEffect, useRef, useState } from 'react';
import { useParams, useOutletContext, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { isTmpId, FormBySchema } from 'drf-react-by-schema';
import dayjs from 'dayjs';

import FormButtons from '../components/FormButtons';
import { Idade } from 'src/components/customFields/Idade';

export default function Pessoa() {
    const model = 'pessoa';
    const basePath = '/';
    const objTitleField = 'pessoa';
    const params = useParams();
    /**
     * @type object
     */
    const {
        loadSinglePageData,
        onSubmit,
        onDeleteModel,
        pageBasics,
        pageForm,
        optionsAC,
        setOptionsAC,
        getAutoComplete,
    } = useOutletContext();
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        setValue,
        formState: { errors, isDirty },
        watch,
        register,
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(pageForm.validationSchema),
    });

    const [idade, setIdade] = useState('');
    const pais = watch('pais');
    const dataDeNascimento = watch('data_de_nascimento');
    const cadeirante = watch('cadeirante');
    const baixaVisao = watch('baixa_visao');
    const naoSubirEscada = watch('nao_subir_escada');
    const idadeChanged = useRef(false);
    const disableAutoNaoDormirEmCima = useRef(false);

    const loadAllData = async () => {
        const defaults = {};
        loadSinglePageData({
            model,
            objTitleField,
            objId: params.objId,
            defaults,
            basePath,
        }).then((values) => {
            reset(values);
        });
        const newOptionsAC = {};
        for (const key of ['uf', 'pais', 'organizacao', 'restricao_alimentar', 'responsavel']) {
            if (!optionsAC || !optionsAC[model]) {
                const ret = await getAutoComplete(key);
                if (ret !== false) {
                    newOptionsAC[key] = ret;
                }
            }
        }
        setOptionsAC(newOptionsAC);
    };

    useEffect(() => {
        loadAllData();
    }, [params.objId]);

    useEffect(() => {
        if (pageForm.forceReload) {
            loadAllData();
        }
    }, [pageForm.forceReload]);

    useEffect(() => {
        if (!pais || pais.id !== '61') {
            setValue('uf', null);
        }
    }, [pais]);

    useEffect(() => {
        if (idadeChanged.current) {
            idadeChanged.current = false;
            return;
        }
        if (!dataDeNascimento) {
            setIdade('');
            return;
        }
        const newIdade = dayjs().diff(dataDeNascimento, 'years');
        setIdade(isNaN(newIdade) ? '' : newIdade.toString());
    }, [dataDeNascimento]);

    useEffect(() => {
        if (disableAutoNaoDormirEmCima.current) {
            return;
        }
        setValue(
            'nao_dormir_em_cima',
            (idade && parseInt(idade) >= 60) || baixaVisao || cadeirante || naoSubirEscada
        );
    }, [idade, baixaVisao, cadeirante, naoSubirEscada]);

    const formButtonProps = {
        objId: params.objId || null,
        onDeleteModel,
        formDisabled: !isDirty,
        title: isTmpId(pageBasics.id) ? 'Nova Pessoa' : 'Editar Pessoa',
    };

    const fieldsLayout = [
        {
            title: 'Informações básicas',
            rows: [
                'nome',
                'email',
                'telefone',
                [
                    'genero',
                    'data_de_nascimento',
                    {
                        key: 'idade',
                        CustomElement: Idade,
                    },
                ],
                ['pais', 'uf', 'idiomas'],                
                'organizacao',
                ['tipo_de_documento', 'documento'],
            ],
        },
        {
            title: 'Restrições',
            rows: [
                'restricao_alimentar',
                'cadeirante',
                'baixa_visao',
                'nao_subir_escada',
                'nao_dormir_em_cima',
            ],
        },
        {
            title: 'Observações',
            rows: ['observacoes_sobre_saude'],
        },
    ];
    const fieldsProps = {
        idade: {
            value: idade,
            onValueChange: (event) => {
                const newIdade = event.target.value
                    ? event.target.value.match(/\d+/g).join('')
                    : '';
                setIdade(newIdade);
                const newDataDeNascimento = newIdade
                    ? dayjs(`${dayjs().subtract(parseInt(newIdade), 'years').year()}-01-01`)
                    : null;
                idadeChanged.current = true;
                setValue('data_de_nascimento', newDataDeNascimento);
            },
        },
        uf: {
            sx: { width: 250 },
            disabled: !pais || pais.id !== 61,
        },
        tipo_de_documento: {
            sx: { width: 400 },
        },
        nao_dormir_em_casa: {
            onValueChange: () => {
                disableAutoNaoDormirEmCima.current = true;
            },
        },
    };

    return (
        <>
            {pageForm.schema === false && <Navigate to="/login" replace />}
            {pageBasics.model === model && pageForm.initialValues && control && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormButtons {...formButtonProps} />
                    <Box>
                        <FormBySchema
                            fieldsLayout={fieldsLayout}
                            fieldsProps={fieldsProps}
                            control={control}
                            register={register}
                            errors={errors}
                            schema={pageForm.schema}
                            optionsAC={optionsAC}
                            setOptionsAC={setOptionsAC}
                            forceReload={pageForm.forceReload}
                            getValues={getValues}
                            setValue={setValue}
                        />
                    </Box>
                    <FormButtons {...formButtonProps} bottom={true} />
                </form>
            )}
        </>
    );
}

import theme from './theme';

export const content = {
    flexGrow: 1,
    pr: 2,
    pl: 2,
    pt: 2,
    pb: 0,
};

export const main = {
    ...content,
    ml: 7,
};

export const flexRow = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
};

export const topBar = {
    ...flexRow,
    width: '100%',
    pl: 3,
    pr: 6,
};

export const loadingBox = {
    ...flexRow,
    height: '100%',
    backgroundColor: '#ccc',
    justifyContent: 'center',
};

export const loadingBoxWhite = {
    ...flexRow,
    height: '100%',
    justifyContent: 'center',
};

export const actionButtons = {
    ...flexRow,
    width: '100%',
    alignItems: 'flex-start',
};

export const inLineForm = {
    ...flexRow,
    width: '100%',
    justifyContent: 'flex-start',
};

export const flexColumn = {
    ...flexRow,
    flexDirection: 'column',
};

export const flexRowGrow = {
    ...flexRow,
    flexGrow: 1,
};

export const topBarOverList = {
    ...flexRowGrow,
    mb: 2,
};

export const dataGrid = {
    height: 'calc(100vh - 180px)',
    mt: 2,
};

export const dataGridWithTabs = {
    ...dataGrid,
    mt: 0,
};

export const dataGridFixedHeight = {
    ...dataGrid,
    height: 400,
};

export const fullWidthButton = {
    mt: 3,
    width: '100%',
};

export const formCard = {
    width: '100%',
    backgroundColor: theme.palette.formCard.main,
    mt: 2,
    mb: 2,
};

export const formCardContent = {
    maxHeight: 350,
    overflow: 'scroll',
};

export const metabaseAppEmbed = {
    height: 2000,
};

export const geoPicker = {
    height: 350,
    width: '100%',
    mt: 0,
};

const SETTINGS = {
  HOME: 'https://api.enff.eita.org.br',
    METABASE: {
        URL: 'https://metabase.enff.eita.org.br',
        SECRET_KEY: 'd1ab73ccf858c933d032fa4b4380981bce65a19c9fc6734f9a5dbe83ddaeefff',
        EMAIL: 'dtygel@eita.org.br',
    },
};

export default SETTINGS;

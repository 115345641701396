import React, { useEffect, useState } from 'react';
import { useParams, useOutletContext, Navigate, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { DetailBySchema, GenericModelList, GenericRelatedModelList, SelectEditInputCell } from 'drf-react-by-schema';
import { Layout } from 'src/styles';
import { IdadeDetail } from 'src/components/customFields/IdadeDetail';
import dayjs from 'dayjs';

export default function EquipeDetail() {
    const [idade, setIdade] = useState('');
    const model = 'equipe';
    const basePath = '/';
    const objTitleField = 'pessoa';
    const fieldsLayout = [
        {
            title: 'Informações básicas',
            rows: [
                ['pessoa', 'pessoa_organizacao'],
                ['setor', 'funcao'],
                ['cpf', 'rg'],
                ['raca_etnia', 'orientacao_sexual'],
            ],
        },
    ];
    const fieldsProps = {
        idade: { value: idade },
    };
    const params = useParams();
    /**
     * @type object
     */
    const { loadSinglePageData, pageBasics, pageForm } = useOutletContext();

    const loadAllData = () => {
        const defaults = {};
        loadSinglePageData({
            model,
            objTitleField,
            objId: params.objId,
            defaults,
            basePath,
        }).then((values) => {
            const newIdade = dayjs().diff(values.data_de_nascimento, 'years');
            setIdade(isNaN(newIdade) ? '' : newIdade.toString());
        });
    };

    useEffect(() => {
        loadAllData();
    }, [params.objId]);

    useEffect(() => {
        if (pageForm.forceReload) {
            loadAllData();
        }
    }, [pageForm.forceReload]);

    const columnFields = [
        'dia_da_semana',
        'refeicao',
    ];
    const indexField = 'nome';
    const minWidthFields = {
        atividade: 405,
        vaga: 200,
        entrada_prevista: 280,
        saida_prevista: 280,
    };

    const customColumnOperations = (column) => {
        if (column.field === 'vaga') {
            column.renderEditCell = (params) => {
                if (params.row.vagas_disponiveis) {
                    return (
                        <SelectEditInputCell
                            {...params}
                            column={column}
                            type="field"
                            optionsAC={{ vaga: params.row.vagas_disponiveis }}
                            isIndexField={false}
                            onEditModel={null}
                        />
                    );
                } else {
                    return (
                        <Box>
                            <i>(Salve e volte para definir vaga)</i>
                        </Box>
                    );
                }
            };
        }
        return column;
    };

    const editLink = basePath + model + '/' + params.objId;

    return (
        <>
            {pageForm.schema === false && <Navigate to="/login" replace />}
            {pageBasics.model === model && pageForm.initialValues && (
                <>
                    <Box>
                        <Typography variant="h1">
                            {pageForm.initialValues[objTitleField].label}
                        </Typography>
                        <DetailBySchema
                            values={pageForm.initialValues}
                            schema={pageForm.schema}
                            editLink={editLink}
                            editLabel={'Editar'}
                            fieldsLayout={fieldsLayout}
                            fieldsProps={fieldsProps}
                            sxRow={{ marginTop: 2, backgroundColor: '#eee' }}
                            sxRowMultiple={{ ...Layout.flexRow, backgroundColor: '#eee' }}
                            sxField={{ marginTop: 2, padding: 2 }}
                            sxLabel={{ marginTop: 0, fontSize: 10, textTransform: 'uppercase' }}
                            sxValue={{ marginBottom: 1, fontSize: 20 }}
                            sxValueList={{ margin: 0 }}
                            sxValueListItem={{ color: '#000', margin: 0 }}
                            sxValueListItemText={{ color: '#000' }}
                        />
                    </Box>
                    <Box className="grid_wrapper">
                        <Typography variant="h2">
                            Alimentação de {pageForm.initialValues.pessoa.label}
                        </Typography>
                        <GenericRelatedModelList
                            model="equipe"
                            id={params.objId}
                            relatedModel="alimentacao_equipe"
                            label="Alimentacao"
                            columnFields={columnFields}
                            creatableFields={[]}
                            disabledFields={[]}
                            hiddenFields={[]}
                            minWidthFields={minWidthFields}
                            indexField={indexField}
                            hasBulkSelect={true}
                            // onSelectActions={onSelectActions}
                            sumRows={null}
                            customColumnOperations={customColumnOperations}
                            reloadAfterRowUpdate={true}
                            isAutoHeight={false}
                            tableAutoHeight={false}
                            indexFieldBasePath={'/alimentacao_equipe/'}
                            indexFieldViewBasePath={'/alimentacao_equipe/detalhe/'}
                            paginationMode="server"
                            actions={['editInline', 'edit', 'view']}
                            LinkComponent={Link}
                            // defaultValues={defaultValues}
                            // customFieldFormLayouts={customFieldFormLayouts}
                        />
                    </Box>
                </>
            )}
        </>
    );
}

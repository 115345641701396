import React, { useEffect, useState } from 'react';
import { useOutletContext, Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { GenericModelList } from 'drf-react-by-schema';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import '../styles/globals.css';
import '../styles/main.css';
import '../styles/home.css';
import HospedagemColumnOperations from 'src/components/HospedagemColumnOperations';
import { pessoaCustomFieldFormLayouts } from 'src/utils/utils';

export default function Transporte() {
    /**
     * @type object
     */
    const { pageBasics } = useOutletContext();
    const dia = pageBasics.day.format('YYYY-MM-DD');

    const minWidthFields = { pessoa: 250, atividade: 200, vaga: 200 };
    const columnFields = [
        'pessoa',
        'atividade',
        'entrada_prevista',
        'saida_prevista',
        'local_chegada',
        'data_hora_chegada',
        'complemento_chegada',
        'local_partida',
        'data_hora_partida',
        'complemento_partida'
    ];
    const indexField = 'pessoa';
    const sumRows = {
        rows: [],
    };
    const customColumnOperations = HospedagemColumnOperations;
    const customFieldFormLayouts = pessoaCustomFieldFormLayouts();

    const [modo, setModo] = useState('em_andamento');
    const handleModo = (event, newModo) => {
        setModo(newModo);
    };

    return (
        <>
            <Typography variant="h1" sx={{ mb: 3 }}>
                Transporte
            </Typography>
            <ToggleButtonGroup value={modo} exclusive onChange={handleModo}>
                <ToggleButton value="entradas_do_dia">Entradas do dia</ToggleButton>
                <ToggleButton value="saidas_do_dia">Saídas do dia</ToggleButton>                
                <ToggleButton value="em_andamento">Em andamento</ToggleButton>
                <ToggleButton value="proximas">Próximas</ToggleButton>
                <ToggleButton value="passadas">Passadas</ToggleButton>            
                <ToggleButton value="">Todas</ToggleButton>
            </ToggleButtonGroup>            
            <Box className="grid_wrapper">
                <Typography variant="h2">Chegadas e saídas</Typography>                                
                <GenericModelList
                    columnFields={columnFields}
                    hiddenFields={[]}
                    minWidthFields={minWidthFields}
                    indexField={indexField}
                    sumRows={sumRows}
                    customColumnOperations={customColumnOperations}
                    isAutoHeight={true}
                    tableAutoHeight={true}
                    customLinkDestination={null}
                    model={'hospedagem'}
                    indexFieldBasePath={`/hospedagem/`}
                    indexFieldViewBasePath={`/hospedagem/detalhe/`}
                    forceReload={false}
                    LinkComponent={Link}
                    hasHeader={false}
                    paginationMode="server"
                    queryParams={[`modo=${modo}&dia=${dia}`]}
                    actions={['editInline', 'edit', 'view']}
                    isEditable={true}
                    customFieldFormLayouts={customFieldFormLayouts}
                />
            </Box>
        </>
    );
}

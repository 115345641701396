import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import PAGES from '../pageTree';

const drawerWidth = 320;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    })
);

export default function SidebarMenu() {
    const location = useLocation();
    const page = location.pathname.split('/')[1];
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const handleSidebarClose = () => {
        setSidebarOpen(false);
    };

    const handleSidebarOpen = () => {
        setSidebarOpen(true);
    };

    return (
        <Drawer
            anchor="left"
            className="sidebar"
            // hideBackdrop={false}
            open={sidebarOpen}
            variant="permanent"
            onClose={handleSidebarClose}
            onMouseEnter={handleSidebarOpen}
            onMouseLeave={handleSidebarClose}
            ModalProps={{
                keepMounted: true,
            }}>
            <List>
                {Object.entries(PAGES).map((entry) => {
                    const [key, pageInfo] = entry;
                    if (pageInfo.skipSidebar) {
                        return <React.Fragment key={key}></React.Fragment>;
                    }
                    return (
                        <React.Fragment key={key}>
                            {pageInfo.home ? (
                                <ListItemButton component={Link} to="/" selected={page === ''}>
                                    <ListItemIcon>{pageInfo.icon}</ListItemIcon>
                                    <ListItemText primary={pageInfo.title} />
                                </ListItemButton>
                            ) : (
                                <ListItemButton
                                    component={Link}
                                    to={`/${key}`}
                                    selected={page === key}>
                                    <ListItemIcon>{pageInfo.icon}</ListItemIcon>
                                    <ListItemText primary={pageInfo.title} />
                                </ListItemButton>
                            )}
                        </React.Fragment>
                    );
                })}
            </List>
        </Drawer>
    );
}

import SETTINGS from './config';
const apiToken = `${SETTINGS.HOME}/api-auth/token`;

const CONSTANTS = {
    HOME: SETTINGS.HOME,
    API: {
        url: SETTINGS.HOME,
        api: `${SETTINGS.HOME}/api`,
        autocomplete: `${SETTINGS.HOME}/autocomplete`,
        JSONSchema: `${SETTINGS.HOME}/jsonschema`,
        apiTokenUrl: `${apiToken}/`,
        getToken: `${apiToken}/`,
        refreshToken: `${apiToken}/refresh/`,
        verifyToken: `${apiToken}/verify/`,
        signUp: `${SETTINGS.HOME}/api-auth/signup/`,
    },
    METABASE: SETTINGS.METABASE,
    FIRST_BATCH_LENGTH: 100,
    CAL_COLORS: ['#ffd6ff', '#e7c6ff', '#c8b6ff', '#b8c0ff', '#bbd0ff'],
};

CONSTANTS.emptyFilters = {
    q: '',
};

export default CONSTANTS;

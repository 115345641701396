import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useAPIWrapper } from 'drf-react-by-schema';

import '../styles/globals.css';
import '../styles/main.css';
import '../styles/home.css';

import RefeicaoBox from 'src/components/RefeicaoBox';

export default function Cozinha() {
    /**
     * @type object
     */
    const { pageBasics } = useOutletContext();
    const { getGenericModelList } = useAPIWrapper();

    const [refeicoes, setRefeicoes] = useState(null);

    const getRefeicoesAsync = async () => {
        if (pageBasics.day) {
            const newRefeicaoList = await getGenericModelList({
                model: 'refeicao',
                columnFields: ['id', 'nome', 'hospedagens', 'horario_inicio', 'horario_fim'],
                queryParams: [`dia=${pageBasics.day.format('YYYY-MM-DD')}`],
            });
            if (newRefeicaoList) {
                setRefeicoes(newRefeicaoList.data);
            }
        }
    };

    useEffect(() => {
        getRefeicoesAsync();
    }, [pageBasics.day]);

    if (!refeicoes) {
        return <></>;
    }

    return (
        <>
            <Typography variant="h1" sx={{ mb: 3 }}>
                Cozinha
            </Typography>
            <Box className="grid_wrapper">
                <Typography variant="h2">Refeições</Typography>
                <Box className="refeicao_wrapper">
                    {refeicoes.map((refeicao) => (
                        <RefeicaoBox
                            key={refeicao.id}
                            label={refeicao.label}
                            nRefeicoes={refeicao.n_refeicoes}
                        />
                    ))}
                </Box>
            </Box>
        </>
    );
}

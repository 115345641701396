import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { GenericModelList } from 'drf-react-by-schema';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Layout } from 'src/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import dayjs from 'dayjs';
import LoadingButton from '@mui/lab/LoadingButton';

import '../styles/globals.css';
import '../styles/main.css';
import '../styles/home.css';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

export default function DialogBulkActions({
    onSelectActions,
    actionsDialogOpen,
    setActionsDialogOpen,
    actionsDialogLoading,
    actionsDialogData,
    handleActionsDialogSubmit,
    setDevolveuChave = null,
    setDevolveuCopo = null,
    setRecebeuChave = null,
    setRecebeuCopo = null,
}) {
    return (
        <Dialog
            open={actionsDialogOpen !== null}
            onClose={() => {
                if (!actionsDialogLoading) {
                    setActionsDialogOpen(null);
                }
            }}>
            <DialogTitle sx={Layout.flexColumn}>
                {actionsDialogOpen !== null && 'title' in onSelectActions[actionsDialogOpen]
                    ? onSelectActions[actionsDialogOpen].title
                    : ''}
            </DialogTitle>
            <DialogContent>
                <List>
                    {actionsDialogOpen !== null &&
                        ['checkOut', 'checkIn'].includes(
                            onSelectActions[actionsDialogOpen].key
                        ) && (
                            <ListItem key="header">
                                <ListItemText
                                    sx={{ width: 100 }}
                                    secondary={`${
                                        onSelectActions[actionsDialogOpen].key === 'checkOut'
                                            ? 'Devolveu'
                                            : 'Recebeu'
                                    } Copo?`}
                                />
                                <ListItemText
                                    sx={{ width: 100 }}
                                    secondary={`${
                                        onSelectActions[actionsDialogOpen].key === 'checkOut'
                                            ? 'Devolveu'
                                            : 'Recebeu'
                                    } Chave?`}
                                />
                                <ListItemText sx={{ width: 300 }} primary="" secondary="" />
                            </ListItem>
                        )}
                    <Divider />
                    {actionsDialogData.map((item) => (
                        <ListItem key={item.id}>
                            {actionsDialogOpen !== null &&
                                onSelectActions[actionsDialogOpen].key === 'checkOut' && (
                                    <>
                                        <ListItemIcon sx={{ width: 100 }}>
                                            {item.recebeu_copo && (
                                                <Checkbox
                                                    id={`${item.id}`}
                                                    onChange={(event, checked) => {
                                                        if (setDevolveuCopo) {
                                                            setDevolveuCopo((oldDevolveuCopo) => {
                                                                return {
                                                                    ...oldDevolveuCopo,
                                                                    [event.target.id]: checked,
                                                                };
                                                            });
                                                        }
                                                    }}
                                                />
                                            )}
                                        </ListItemIcon>
                                        <ListItemIcon sx={{ width: 100 }}>
                                            {item.recebeu_chave && (
                                                <Checkbox
                                                    id={`${item.id}`}
                                                    onChange={(event, checked) => {
                                                        if (setDevolveuChave) {
                                                            setDevolveuChave((oldDevolveuChave) => {
                                                                return {
                                                                    ...oldDevolveuChave,
                                                                    [event.target.id]: checked,
                                                                };
                                                            });
                                                        }
                                                    }}
                                                />
                                            )}
                                        </ListItemIcon>
                                    </>
                                )}
                            {actionsDialogOpen !== null &&
                                onSelectActions[actionsDialogOpen].key === 'checkIn' && (
                                    <>
                                        <ListItemIcon sx={{ width: 100 }}>
                                            <Checkbox
                                                id={`${item.id}`}
                                                onChange={(event, checked) => {
                                                    if (setRecebeuCopo) {
                                                        setRecebeuCopo((oldRecebeuCopo) => {
                                                            return {
                                                                ...oldRecebeuCopo,
                                                                [event.target.id]: checked,
                                                            };
                                                        });
                                                    }
                                                }}
                                            />
                                        </ListItemIcon>
                                        <ListItemIcon sx={{ width: 100 }}>
                                            <Checkbox
                                                id={`${item.id}`}
                                                onChange={(event, checked) => {
                                                    if (setRecebeuChave) {
                                                        setRecebeuChave((oldRecebeuChave) => {
                                                            return {
                                                                ...oldRecebeuChave,
                                                                [event.target.id]: checked,
                                                            };
                                                        });
                                                    }
                                                }}
                                            />
                                        </ListItemIcon>
                                    </>
                                )}
                            <ListItemText
                                sx={{ width: 300 }}
                                primary={
                                    item.search_pessoa
                                        ? item.search_pessoa
                                        : item.vaga
                                        ? item.vaga.label
                                        : 'sem vaga'
                                }
                                secondary={item.atividade.label || ''}
                            />
                        </ListItem>
                    ))}
                </List>
                <Box sx={{ ...Layout.flexRow, justifyContent: 'flex-end' }}>
                    <Button
                        onClick={() => {
                            if (!actionsDialogLoading) {
                                setActionsDialogOpen(null);
                            }
                        }}>
                        Cancelar
                    </Button>
                    <LoadingButton
                        variant="contained"
                        size="medium"
                        loading={actionsDialogLoading}
                        onClick={handleActionsDialogSubmit}>
                        {actionsDialogOpen !== null &&
                        'applyButtonLabel' in onSelectActions[actionsDialogOpen]
                            ? onSelectActions[actionsDialogOpen].applyButtonLabel
                            : 'Aplicar'}
                    </LoadingButton>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

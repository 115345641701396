import React, { useEffect, useState } from 'react';
import { useParams, useOutletContext, Navigate, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { DetailBySchema, GenericModelList, SelectEditInputCell } from 'drf-react-by-schema';
import { Layout } from 'src/styles';
import { IdadeDetail } from 'src/components/customFields/IdadeDetail';
import dayjs from 'dayjs';

export default function PessoaDetail() {
    const [idade, setIdade] = useState('');
    const model = 'pessoa';
    const basePath = '/';
    const objTitleField = 'nome';
    const fieldsLayout = [
        {
            title: 'Informações básicas',
            rows: [
                'nome',
                'email',
                'telefone',
                [
                    'genero',
                    'data_de_nascimento',
                    {
                        key: 'idade',
                        CustomElement: IdadeDetail,
                    },
                ],
                ['pais', 'uf'],
                'organizacao',
                ['tipo_de_documento', 'documento'],
            ],
        },
        {
            title: 'Criança / Adolescente',
            rows: [['responsavel', 'demanda_ciranda']],
        },
        {
            title: 'Restrições',
            rows: [
                'restricao_alimentar',
                'cadeirante',
                'baixa_visao',
                'nao_subir_escada',
                'nao_dormir_em_cima',
            ],
        },
        {
            title: 'Observações',
            rows: ['observacoes_sobre_saude'],
        },
    ];
    const fieldsProps = {
        idade: { value: idade },
    };
    const params = useParams();
    /**
     * @type object
     */
    const { loadSinglePageData, pageBasics, pageForm } = useOutletContext();

    const loadAllData = () => {
        const defaults = {};
        loadSinglePageData({
            model,
            objTitleField,
            objId: params.objId,
            defaults,
            basePath,
        }).then((values) => {
            const newIdade = dayjs().diff(values.data_de_nascimento, 'years');
            setIdade(isNaN(newIdade) ? '' : newIdade.toString());
        });
    };

    useEffect(() => {
        loadAllData();
    }, [params.objId]);

    useEffect(() => {
        if (pageForm.forceReload) {
            loadAllData();
        }
    }, [pageForm.forceReload]);

    const columnFields = [
        'atividade',
        'vaga',
        'entrada_prevista',
        'saida_prevista',
        'entrada',
        'saida',
    ];
    const indexField = 'nome';
    const minWidthFields = {
        atividade: 405,
        vaga: 200,
        entrada_prevista: 280,
        saida_prevista: 280,
    };

    const customColumnOperations = (column) => {
        if (column.field === 'vaga') {
            column.renderEditCell = (params) => {
                if (params.row.vagas_disponiveis) {
                    return (
                        <SelectEditInputCell
                            {...params}
                            column={column}
                            type="field"
                            optionsAC={{ vaga: params.row.vagas_disponiveis }}
                            isIndexField={false}
                            onEditModel={null}
                        />
                    );
                } else {
                    return (
                        <Box>
                            <i>(Salve e volte para definir vaga)</i>
                        </Box>
                    );
                }
            };
        }
        return column;
    };

    const editLink = basePath + model + '/' + params.objId;

    return (
        <>
            {pageForm.schema === false && <Navigate to="/login" replace />}
            {pageBasics.model === model && pageForm.initialValues && (
                <>
                    <Box>
                        <Typography variant="h1">
                            {pageForm.initialValues[objTitleField].nome}
                        </Typography>
                        <DetailBySchema
                            values={pageForm.initialValues}
                            schema={pageForm.schema}
                            editLink={editLink}
                            editLabel={'Editar'}
                            fieldsLayout={fieldsLayout}
                            fieldsProps={fieldsProps}
                            sxRow={{ marginTop: 2, backgroundColor: '#eee' }}
                            sxRowMultiple={{ ...Layout.flexRow, backgroundColor: '#eee' }}
                            sxField={{ marginTop: 2, padding: 2 }}
                            sxLabel={{ marginTop: 0, fontSize: 10, textTransform: 'uppercase' }}
                            sxValue={{ marginBottom: 1, fontSize: 20 }}
                            sxValueList={{ margin: 0 }}
                            sxValueListItem={{ color: '#000', margin: 0 }}
                            sxValueListItemText={{ color: '#000' }}
                        />
                    </Box>
                    <Box className="grid_wrapper">
                        <Typography variant="h2">
                            Hospedagens de {pageForm.initialValues.nome}
                        </Typography>
                        <GenericModelList
                            columnFields={columnFields}
                            hiddenFields={[]}
                            minWidthFields={minWidthFields}
                            indexField={indexField}
                            hasBulkSelect={false}
                            sumRows={null}
                            customColumnOperations={customColumnOperations}
                            isAutoHeight={true}
                            tableAutoHeight={true}
                            customLinkDestination={null}
                            model={'hospedagem'}
                            indexFieldBasePath={'/hospedagem/'}
                            indexFieldViewBasePath={'/hospedagem/detalhe/'}
                            forceReload={false}
                            LinkComponent={Link}
                            hasHeader={false}
                            paginationMode="server"
                            queryParams={[`pessoa_id=${params.objId}`]}
                            actions={['editInline', 'edit', 'view']}
                            isEditable={true}
                        />
                    </Box>
                </>
            )}
        </>
    );
}

import React, { useEffect, useState } from 'react';
import { useAPIWrapper } from 'drf-react-by-schema';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import QuartoRelatedHospedagem from './QuartoRelatedHospedagem';
import QuartoTabHead from './QuartoTabHead';

export default function BlocoTab({ blocoId, pageBasics }) {
    const { getGenericModelList, getAutoComplete, getRawData } = useAPIWrapper();
    const [quartos, setQuartos] = useState(null);
    const [optionsAC, setOptionsAC] = useState(null);

    const carregaQuartos = async () => {
        const newQuartos = await getGenericModelList({
            model: 'bloco',
            id: blocoId,
            relatedModel: 'quarto',
            columnFields: ['id', 'nome', 'acessibilidade', 'possui_escada'],
        });
        if (newQuartos) {
            setQuartos(newQuartos.data);
            return;
        }
        console.log('Erro ao carregar quartos');
    };

    const carregaOptionsAC = async () => {
        const fields = ['pessoa', 'atividade', 'vaga'];
        const newOptionsAC = {};
        for (const field of fields) {
            const options = await getAutoComplete(field);
            if (options) {
                newOptionsAC[field] = options;
            }
        }
        setOptionsAC(newOptionsAC);
    };

    const [quartos_data, setQuartosData] = useState(null);

    const getQuartosDataAsync = async () => {
        const newQuartosData = await getRawData(
            `bloco_quarto_vagas_vazias/?dia=${pageBasics.day.format(
                'YYYY-MM-DD'
            )}&bloco_id=${blocoId}`
        );
        if (newQuartosData) {
            setQuartosData(newQuartosData);
            return;
        }
        console.log('erro ao tentar baixar QuartosData!');
    };

    useEffect(() => {
        carregaQuartos();
        getQuartosDataAsync();
    }, [blocoId, pageBasics.day]);

    // useEffect(() => {
    //     getQuartosDataAsync();
    // }, [onEditModel]);

    useEffect(() => {
        if (!optionsAC) {
            carregaOptionsAC();
        }
    }, []);

    if (!quartos || !quartos_data || !optionsAC) {
        return <></>;
    }

    return (
        <>
            {quartos.map((quarto, idx) => (
                <Accordion key={quarto.id}>
                    <AccordionSummary
                        className="accordion_quarto"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${idx}-content`}
                        id={`panel${idx}-header`}>
                        <QuartoTabHead
                            nome={quarto.nome}
                            escadaBool={quarto.possui_escada}
                            acessibilidadeBool={quarto.acessibilidade}
                            vagas_vazias={quartos_data['vagas_vazias'][quarto.id]}
                            genero={quartos_data['genero'][quarto.id]}
                        />
                    </AccordionSummary>
                    <AccordionDetails>
                        <QuartoRelatedHospedagem
                            quartoId={quarto.id}
                            label={quarto.label}
                            pageBasics={pageBasics}
                            optionsAC={optionsAC}
                        />
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    );
}

import React, { useState } from 'react';
import { SelectEditInputCell } from 'drf-react-by-schema';
import GenericPageList from '../pages/GenericPageList';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Box from '@mui/material/Box';
import { pessoaCustomFieldFormLayouts } from '../utils/utils';
import HospedagemColumnOperations from '../components/HospedagemColumnOperations';

export default function HospedagemList() {
    /**
     * @type object
     */

    const columnFields = [
        'pessoa',
        'atividade',
        'entrada_prevista',
        'saida_prevista',
        'vaga',
        'genero',
        'search_vaga',
        'search_pessoa',
        'pessoa_idiomas',
    ];
    const hiddenFields = ['search_vaga', 'search_pessoa'];

    const minWidthFields = { pessoa: 300, atividade: 300, vaga: 200 };
    const indexField = 'pessoa';
    const sumRows = {
        rows: [],
    };

    const [modo, setModo] = useState('em_andamento');

    const handleModo = (event, newModo) => {
        setModo(newModo);
    };

    const customColumnOperations = HospedagemColumnOperations

    const customFieldFormLayouts = pessoaCustomFieldFormLayouts();

    const PreListComponent = () => (
        <>
            <ToggleButtonGroup value={modo} exclusive onChange={handleModo}>
                <ToggleButton value="em_andamento">Em andamento</ToggleButton>
                <ToggleButton value="proximas">Próximas</ToggleButton>
                <ToggleButton value="passadas">Passadas</ToggleButton>
                <ToggleButton value="">Todas</ToggleButton>
            </ToggleButtonGroup>
        </>
    );

    return (
        <GenericPageList
            columnFields={columnFields}
            hiddenFields={hiddenFields}
            indexField={indexField}
            hasBulkSelect={false}
            sumRows={sumRows}
            minWidthFields={minWidthFields}
            hasHeader={true}
            customColumnOperations={customColumnOperations}
            customFieldFormLayouts={customFieldFormLayouts}
            reloadAfterRowUpdate={true}
            PreListComponent={PreListComponent}
            queryParams={[`modo=${modo}`]}
        />
    );
}

import React, { useState } from 'react';
import { SelectEditInputCell } from 'drf-react-by-schema';
import { GenericModelList } from 'drf-react-by-schema';
import { Link } from 'react-router-dom';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Box from '@mui/material/Box';
import { pessoaCustomFieldFormLayouts } from '../utils/utils';
import HospedagemColumnOperations from 'src/components/HospedagemColumnOperations';

export default function Ciranda() {
    /**
     * @type object
     */

    const columnFields = [
        'pessoa',
        'atividade',
        'entrada_prevista',
        'saida_prevista',
        'vaga',
        'observacao_pernoite',
        'responsavel',
        'search_vaga',
        'search_pessoa',
    ];
    const hiddenFields = ['search_vaga', 'search_pessoa'];

    const minWidthFields = { pessoa: 200, atividade: 200, observacao_pernoite: 200, responsavel: 200 };
    const indexField = 'pessoa';
    const sumRows = {
        rows: [],
    };

    const [modo, setModo] = useState('em_andamento');

    const handleModo = (event, newModo) => {
        setModo(newModo);
    };

    const customColumnOperations = HospedagemColumnOperations;

    const customFieldFormLayouts = pessoaCustomFieldFormLayouts();

    return (
        <>
            <ToggleButtonGroup value={modo} exclusive onChange={handleModo}>
                <ToggleButton value="em_andamento">Em andamento</ToggleButton>
                <ToggleButton value="proximas">Próximas</ToggleButton>
                <ToggleButton value="passadas">Passadas</ToggleButton>
                <ToggleButton value="">Todas</ToggleButton>
            </ToggleButtonGroup>        
            <GenericModelList
                columnFields={columnFields}
                hiddenFields={hiddenFields}
                minWidthFields={minWidthFields}
                indexField={indexField}
                sumRows={sumRows}
                customFieldFormLayouts={customFieldFormLayouts}
                customColumnOperations={customColumnOperations}
                isAutoHeight={true}
                tableAutoHeight={true}
                model={'hospedagem'}
                indexFieldBasePath={`/hospedagem/`}
                indexFieldViewBasePath={`/hospedagem/detalhe/`}
                forceReload={false}            
                hasHeader={false}
                paginationMode="server"
                queryParams={[`modo=${modo}&ciranda=1`]}
                actions={['editInline', 'edit', 'view']}
                isEditable={true}
                LinkComponent={Link}
            />
        </>
    );
}

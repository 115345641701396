import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';

const drawerWidth = 320;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(() => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    '& .MuiDrawer-paper': {
        width: drawerWidth,
        paddingTop: 10,
        paddingBottom: 80,
        zIndex: 5,
    },
}));

export default function SidebarSettings({ items, model }) {
    return (
        <Drawer
            anchor="left"
            className="sidebar"
            hideBackdrop={false}
            open={true}
            variant="permanent"
            ModalProps={{
                keepMounted: true,
            }}>
            <Typography variant="h5" sx={{ pl: 2 }}>
                Configurações
            </Typography>
            <List>
                {items.map((endpoint) => (
                    <ListItem disablePadding key={endpoint.model_name}>
                        <Link
                            to={`/settings/${endpoint.model_name}`}
                            style={{ textDecoration: 'none', width: '100%' }}>
                            <ListItemButton selected={endpoint.model_name === model}>
                                <ListItemText primary={endpoint.verbose_name_plural} />
                            </ListItemButton>
                        </Link>
                    </ListItem>
                ))}
            </List>
        </Drawer>
    );
}

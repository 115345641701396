import React, { useState, useReducer, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Navigate } from 'react-router-dom';
import { useAPIWrapper } from 'drf-react-by-schema';

import { Layout } from '../styles';

export default function Login() {
    const [status, setStatus] = useState(null);
    const initialPayload = {
        username: null,
        password: null,
    };
    const reducer = (state, newState) => {
        return { ...state, ...newState };
    };
    /**
     * @type object
     */
    const [payload, setPayload] = useReducer(reducer, initialPayload);
    const { handleLoading, updateUsuaria, loginByPayload, usuaria } = useAPIWrapper();
    const handleSubmit = () => {
        loginByPayload(payload).then((success) => {
            if (success) {
                setStatus('loggedIn');
                updateUsuaria();
                return;
            }
            setStatus('failed');
        });
    };

    useEffect(() => {
        if (usuaria !== null) {
            setStatus(!!usuaria.id ? 'loggedIn' : null);
            setPayload(initialPayload);
            handleLoading(false);
        }
    }, [usuaria]);

    return (
        <>
            {status === 'loggedIn' ? (
                <Navigate to={'/'} replace />
            ) : (
                <Dialog open={true} maxWidth="sm">
                    <DialogTitle sx={Layout.flexColumn}>
                        {/* <img src={icvSidebarIcon} /> */}
                        Acesso
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={Layout.flexColumn}>
                            Insira seu login e senha abaixo
                            {status === 'failed' && (
                                <Alert severity="error">
                                    A autenticação falhou. Confira seu nome de usuária ou senha e
                                    tente novamente.
                                </Alert>
                            )}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="username"
                            label="Nome de usuária(o)"
                            type="email"
                            fullWidth
                            variant="standard"
                            onChange={(e) => {
                                setPayload({ username: e.target.value });
                            }}
                        />
                        <TextField
                            margin="dense"
                            id="password"
                            label="Senha"
                            type="password"
                            fullWidth
                            variant="standard"
                            onKeyPress={(e) => {
                                const code = e.keyCode ? e.keyCode : e.which;
                                if (code === 13) {
                                    handleSubmit();
                                }
                            }}
                            onChange={(e) => {
                                setPayload({ password: e.target.value });
                            }}
                        />
                        <Button
                            variant="contained"
                            size="medium"
                            sx={Layout.fullWidthButton}
                            onClick={handleSubmit}>
                            Entrar
                        </Button>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
}

import React, { useEffect, useRef, useState } from 'react';
import { useParams, useOutletContext, Navigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { isTmpId, FormBySchema, GenericRelatedModelList } from 'drf-react-by-schema';
import dayjs from 'dayjs';

import FormButtons from '../components/FormButtons';
import { pessoaCustomFieldFormLayouts } from '../utils/utils';
import { Typography } from '@mui/material';

export default function Equipe() {
    const model = 'equipe';
    const basePath = '/';
    const objTitleField = 'equipe';
    const params = useParams();
    /**
     * @type object
     */
    const {
        loadSinglePageData,
        onSubmit,
        onDeleteModel,
        pageBasics,
        pageForm,
        optionsAC,
        setOptionsAC,
        getAutoComplete,
    } = useOutletContext();
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        setValue,
        formState: { errors, isDirty },
        watch,
        register,
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(pageForm.validationSchema),
    });

    const [idade, setIdade] = useState('');
    const pais = watch('pais');
    const dataDeNascimento = watch('data_de_nascimento');
    const cadeirante = watch('cadeirante');
    const baixaVisao = watch('baixa_visao');
    const naoSubirEscada = watch('nao_subir_escada');
    const idadeChanged = useRef(false);
    const disableAutoNaoDormirEmCima = useRef(false);

    const loadAllData = async () => {
        const defaults = {};
        loadSinglePageData({
            model,
            objTitleField,
            objId: params.objId,
            defaults,
            basePath,
        }).then((values) => {
            reset(values);
        });
        const newOptionsAC = {};
        for (const key of ['uf', 'pais', 'organizacao', 'restricao_alimentar', 'responsavel']) {
            if (!optionsAC || !optionsAC[model]) {
                const ret = await getAutoComplete(key);
                if (ret !== false) {
                    newOptionsAC[key] = ret;
                }
            }
        }
        setOptionsAC(newOptionsAC);
    };

    useEffect(() => {
        loadAllData();
    }, [params.objId]);

    useEffect(() => {
        if (pageForm.forceReload) {
            loadAllData();
        }
    }, [pageForm.forceReload]);

    useEffect(() => {
        if (!pais || pais.id !== '61') {
            setValue('uf', null);
        }
    }, [pais]);

    useEffect(() => {
        if (idadeChanged.current) {
            idadeChanged.current = false;
            return;
        }
        if (!dataDeNascimento) {
            setIdade('');
            return;
        }
        const newIdade = dayjs().diff(dataDeNascimento, 'years');
        setIdade(isNaN(newIdade) ? '' : newIdade.toString());
    }, [dataDeNascimento]);

    useEffect(() => {
        if (disableAutoNaoDormirEmCima.current) {
            return;
        }
        setValue(
            'nao_dormir_em_cima',
            (idade && parseInt(idade) >= 60) || baixaVisao || cadeirante || naoSubirEscada
        );
    }, [idade, baixaVisao, cadeirante, naoSubirEscada]);

    const formButtonProps = {
        objId: params.objId || null,
        onDeleteModel,
        formDisabled: !isDirty,
        title: isTmpId(pageBasics.id) ? 'Nova Pessoa da Brigada' : 'Editar Pessoa da Brigada',
    };

    const fieldsLayout = [
        {
            title: 'Informações básicas',
            rows: [
                'pessoa',
                ['rg', 'cpf', 'ciranda'],
                ['municipio', 'local'],
                ['setor', 'funcao'],                
                ['chegada', 'saida'],
                ['ano_de_inicio_na_organizacao', 'orientacao_sexual', 'raca_etnia'],
                ['escolaridade', 'area_de_formacao', 'cursando'],
                'criancas'
            ]
        },
    ];

    const columnFields = [
        'dia_da_semana',
        'refeicao',
    ];
    const indexField = 'nome';
    const minWidthFields = {
        atividade: 405,
        vaga: 200,
        entrada_prevista: 280,
        saida_prevista: 280,
    };

    return (
        <>
            {pageForm.schema === false && <Navigate to="/login" replace />}
            {pageBasics.model === model && pageForm.initialValues && control && (
                <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormButtons {...formButtonProps} />
                        <Box>
                            <FormBySchema
                                fieldsLayout={fieldsLayout}
                                customFieldFormLayouts={pessoaCustomFieldFormLayouts()}
                                // fieldsProps={fieldsProps}
                                control={control}
                                register={register}
                                errors={errors}
                                schema={pageForm.schema}
                                optionsAC={optionsAC}
                                setOptionsAC={setOptionsAC}
                                forceReload={pageForm.forceReload}
                                getValues={getValues}
                                setValue={setValue}
                            />
                        </Box>
                        <FormButtons {...formButtonProps} bottom={true} />                    
                    </form>
                    {params.objId != "novo" && 
                        <Box className="grid_wrapper">
                            <Typography variant="h2">
                                Alimentação de {pageForm.initialValues.pessoa.label}
                            </Typography>
                            <GenericRelatedModelList
                                model="equipe"
                                id={params.objId}
                                relatedModel="alimentacao_equipe"
                                label="Alimentacao"
                                columnFields={columnFields}
                                creatableFields={[]}
                                disabledFields={[]}
                                hiddenFields={[]}
                                minWidthFields={minWidthFields}
                                indexField={indexField}
                                hasBulkSelect={true}
                                // onSelectActions={onSelectActions}
                                sumRows={null}
                                // customColumnOperations={customColumnOperations}
                                reloadAfterRowUpdate={true}
                                isAutoHeight={false}
                                tableAutoHeight={false}
                                indexFieldBasePath={'/alimentacao_equipe/'}
                                indexFieldViewBasePath={'/alimentacao_equipe/detalhe/'}
                                paginationMode="server"
                                actions={['editInline', 'edit', 'view']}
                                LinkComponent={Link}
                                // defaultValues={defaultValues}
                                // customFieldFormLayouts={customFieldFormLayouts}
                            />
                        </Box>
                    }
                </>
            )}
        </>
    );
}

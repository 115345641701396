import React from 'react';
import Box from '@mui/material/Box';
import { SelectEditInputCell } from 'drf-react-by-schema';

export default function HospedagemColumnOperations(column) {
    if (column.field === 'vaga') {
        column.renderEditCell = (params) => {
            if (params.row.vagas_disponiveis) {
                return (
                    <SelectEditInputCell
                        {...params}
                        column={column}
                        type="field"
                        optionsAC={{ vaga: [params.row.vaga, ...params.row.vagas_disponiveis] }}
                        isIndexField={false}
                        onEditModel={null}
                    />
                );
            } else {
                return (
                    <Box>
                        <i>(Salve e volte para definir vaga)</i>
                    </Box>
                );
            }
        };
    }
    // TODO: find ways to update entrada_prevista e saida_prevista when user changes atividade
    // if (column.field === 'entrada_prevista') {
    //     console.log(column);
    //     column.valueSetter = (value, params) => {
    //         if (!value && params.row.atividade) {
    //             console.log(params.row.atividade);
    //         }
    //         return value;
    //     };
    // }
    if (column.field === 'pessoa') {
        column.renderCell = (params) => {
            if (params.formattedValue == '' && params.row.genero) {
                return <p id={params.row.id}>Reserva - {params.row.genero.display_name}</p>;
            } else if (params.formattedValue == '') {
                return <p id={params.row.id}>Reserva</p>;
            } else {
                return <p id={params.row.id}>{params.formattedValue}</p>;
            }
        };
    }
    return column;
};
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useOutletContext, Navigate, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { DetailBySchema, GenericRelatedModelList, SelectEditInputCell } from 'drf-react-by-schema';
import { Layout } from 'src/styles';
import ResumoAtividadeBox from 'src/components/ResumoAtividadeBox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import dayjs from 'dayjs';
import DialogBulkActions from 'src/components/DialogBulkActions';
import { pessoaCustomFieldFormLayouts } from '../utils/utils';
import HospedagemColumnOperations from 'src/components/HospedagemColumnOperations';

export default function AtividadeDetail() {
    const model = 'atividade';
    const basePath = '/';
    const objTitleField = 'nome';
    const [fieldsLayout, setFieldsLayout] = useState([
        {
            rows: [
                'nome',
                'organizacoes',
                ['categoria_de_atividade', 'tipo_de_atividade'],
                ['inicio', 'fim', 'n_mulheres', 'n_homens'],
            ],
        },
    ]);
    const params = useParams();
    const [defaultValues, setDefaultValues] = useState({
        entrada_prevista: null,
        saida_prevista: null,
    });
    /**
     * @type object
     */
    const { loadSinglePageData, pageBasics, pageForm, getRawData } = useOutletContext();

    const loadAllData = () => {
        const defaults = {};
        loadSinglePageData({
            model,
            objTitleField,
            objId: params.objId,
            defaults,
            basePath,
        });
    };

    const getContatos = async () => {
        const contatos = await getRawData(`atividade_contatos/?atividade_id=${params.objId}`);
        setContatos(contatos);
    };

    useEffect(() => {
        loadAllData();
        getResumoDaAtividadeAsync();
        getContatos();
    }, [params.objId]);

    useEffect(() => {
        if (pageForm.forceReload) {
            loadAllData();
        }
    }, [pageForm.forceReload]);

    useEffect(() => {
        if (pageBasics.model === model && pageForm.initialValues) {
            setDefaultValues({
                entrada_prevista: pageForm.initialValues.inicio,
                saida_prevista: pageForm.initialValues.fim,
            });
        }
    }, [pageForm.initialValues]);

    const [contatos, setContatos] = useState([]);
    const [actionsDialogLoading, setActionsDialogLoading] = useState(false);
    const [actionsDialogOpen, setActionsDialogOpen] = useState(null);
    const [actionsDialogData, setActionsDialogData] = useState([]);
    const [devolveuCopo, setDevolveuCopo] = useState({});
    const [devolveuChave, setDevolveuChave] = useState({});
    const [recebeuChave, setRecebeuChave] = useState({});
    const [recebeuCopo, setRecebeuCopo] = useState({});
    const bulkUpdateData = useRef(null);

    const columnFields = [
        'pessoa',
        'vaga',
        'observacao_pernoite',
        'entrada_prevista',
        'entrada',
        'saida_prevista',
        'saida',
        'pessoa_idiomas',
        'recebeu_chave',
    ];
    const indexField = 'nome';
    const minWidthFields = {
        pessoa: 400,
        vaga: 200,
        entrada_prevista: 280,
        saida_prevista: 280,
    };

    const customFieldFormLayouts = pessoaCustomFieldFormLayouts();

    const customColumnOperations = HospedagemColumnOperations;
    const editLink = basePath + model + '/' + params.objId;

    const [resumo_da_atividade, setResumoDaAtividade] = useState(null);
    const getResumoDaAtividadeAsync = async () => {
        const newResumoDaAtividade = await getRawData(
            `resumo_da_atividade/?atividade=${params.objId}`
        );
        if (newResumoDaAtividade) {
            setResumoDaAtividade(newResumoDaAtividade);
            return;
        }
        console.log('erro ao tentar baixar newResumoDaAtividade!');
    };

    const onSelectActions = [
        {
            key: 'checkIn',
            title: 'Check In',
            applyButtonLabel: 'Fazer Check In',
            action: (selectionModel, updateData) => {
                setActionsDialogData(selectionModel);
                bulkUpdateData.current = updateData;
                setActionsDialogOpen(0);
                setActionsDialogLoading(false);
            },
        },        
        {
            key: 'checkOut',
            title: 'Check Out',
            applyButtonLabel: 'Fazer Check Out',
            action: (selectionModel, updateData) => {
                setActionsDialogData(selectionModel);
                bulkUpdateData.current = updateData;
                setActionsDialogOpen(1);
                setActionsDialogLoading(false);
            },
        },
        {
            key: 'bulkDelete',
            title: 'Apagar hospedagen(s)',
            action: 'bulkDelete',
        },
        {
            key: 'bulkRemoveVagas',
            title: 'Limpar vagas das hospedagens',
            applyButtonLabel: 'Limpar vagas',
            action: (selectionModel, updateData) => {
                setActionsDialogData(selectionModel);
                bulkUpdateData.current = updateData;
                setActionsDialogOpen(2);
                setActionsDialogLoading(false);
            },
        },
    ];

    const handleActionsDialogSubmit = async () => {
        setActionsDialogLoading(true);
        if (!bulkUpdateData.current || actionsDialogOpen === null) {
            return;
        }
        const now = dayjs().toISOString();
        let newData = null;
        switch (onSelectActions[actionsDialogOpen].key) {
            case 'checkOut':
                newData = actionsDialogData.map((hospedagem) => {
                    return {
                        ...hospedagem,
                        saida: now,
                        devolveu_chave:
                            hospedagem.id in devolveuChave ? devolveuChave[hospedagem.id] : false,
                        devolveu_copo:
                            hospedagem.id in devolveuCopo ? devolveuCopo[hospedagem.id] : false,
                    };
                });
                break;
            case 'checkIn':
                newData = actionsDialogData.map((hospedagem) => {
                    return {
                        ...hospedagem,
                        entrada: now,
                        recebeu_chave:
                            hospedagem.id in recebeuChave ? recebeuChave[hospedagem.id] : false,
                        recebeu_copo:
                            hospedagem.id in recebeuCopo ? recebeuCopo[hospedagem.id] : false,
                    };
                });
                break;
            case 'bulkRemoveVagas':
                newData = actionsDialogData.map((hospedagem) => {
                    return {
                        ...hospedagem,
                        vaga: null,
                    };
                });
                break;
        }
        if (newData) {
            await bulkUpdateData.current(newData);
        }
        setActionsDialogOpen(null);
    };

    if (!resumo_da_atividade) {
        return <></>;
    }

    return (
        <>
            {pageForm.schema === false && <Navigate to="/login" replace />}
            {pageBasics.model === model && pageForm.initialValues && (
                <>
                    <Box>
                        <Typography variant="h1">
                            {pageForm.initialValues[objTitleField]}
                        </Typography>
                        <DetailBySchema
                            values={pageForm.initialValues}
                            schema={pageForm.schema}
                            editLink={editLink}
                            editLabel={'Editar'}
                            fieldsLayout={fieldsLayout}
                            sxRow={{ marginTop: 2, backgroundColor: '#eee' }}
                            sxRowMultiple={{ ...Layout.flexRow, backgroundColor: '#eee' }}
                            sxField={{ marginTop: 2, padding: 2 }}
                            sxLabel={{ marginTop: 0, fontSize: 10, textTransform: 'uppercase' }}
                            sxValue={{ marginBottom: 1, fontSize: 20 }}
                            sxValueList={{ margin: 0 }}
                            sxValueListItem={{ color: '#000', margin: 0 }}
                            sxValueListItemText={{ color: '#000' }}
                        />
                    </Box>
                    <Box className="grid_wrapper">
                        <Typography variant="h2">Contatos</Typography>
                        <List>
                            {contatos &&
                                contatos.map((contato, idx) => (
                                    <ListItem key={idx}>{contato}</ListItem>
                                ))}
                        </List>
                    </Box>
                    <Box className="grid_wrapper">
                        <Typography variant="h2">Resumo</Typography>
                        <Box className="refeicao_wrapper atividade_wrapper">
                            {resumo_da_atividade.map((resumo, idx) => (
                                <ResumoAtividadeBox
                                    key={idx}
                                    label={resumo[0]}
                                    content={resumo[1]}
                                />
                            ))}
                        </Box>
                    </Box>
                    <Box className="grid_wrapper">
                        <GenericRelatedModelList
                            model="atividade"
                            id={params.objId}
                            relatedModel="hospedagem"
                            label="Hospedagens"
                            columnFields={columnFields}
                            creatableFields={[]}
                            disabledFields={[]}
                            hiddenFields={[]}
                            minWidthFields={minWidthFields}
                            indexField={indexField}
                            hasBulkSelect={true}
                            onSelectActions={onSelectActions}
                            sumRows={null}
                            customColumnOperations={customColumnOperations}
                            reloadAfterRowUpdate={true}
                            isAutoHeight={false}
                            tableAutoHeight={false}
                            indexFieldBasePath={'/hospedagem/'}
                            indexFieldViewBasePath={'/hospedagem/detalhe/'}
                            paginationMode="server"
                            actions={['editInline', 'edit', 'view']}
                            LinkComponent={Link}
                            defaultValues={defaultValues}
                            customFieldFormLayouts={customFieldFormLayouts}
                        />
                    </Box>
                    <DialogBulkActions
                        onSelectActions={onSelectActions}
                        actionsDialogOpen={actionsDialogOpen}
                        setActionsDialogOpen={setActionsDialogOpen}
                        actionsDialogLoading={actionsDialogLoading}
                        actionsDialogData={actionsDialogData}
                        handleActionsDialogSubmit={handleActionsDialogSubmit}
                        setDevolveuChave={setDevolveuChave}
                        setDevolveuCopo={setDevolveuCopo}
                        setRecebeuChave={setRecebeuChave}
                        setRecebeuCopo={setRecebeuCopo}
                    />
                </>
            )}
        </>
    );
}

import { createTheme } from '@mui/material/styles';
import { ptBR as corePtBR } from '@mui/material/locale';
import { ptBR } from '@mui/x-data-grid';
import { ptBR as pickersPtBR } from '@mui/x-date-pickers';

ptBR.components.MuiDataGrid.defaultProps.localeText = {
    ...ptBR.components.MuiDataGrid.defaultProps.localeText,
    toolbarQuickFilterPlaceholder: 'Buscar...',
    toolbarQuickFilterLabel: 'Buscar',
    toolbarQuickFilterDeleteIconLabel: 'Limpar busca',
};

const palette = {
    avatars: [
        '#e60049',
        '#0bb4ff',
        '#50e991',
        '#e6d800',
        '#9b19f5',
        '#ffa300',
        '#dc0ab4',
        '#b3d4ff',
        '#00bfa0',
    ],
    avatarsMore: [
        '#023fa5',
        '#8e063b',
        '#d33f6a',
        '#11c638',
        '#ef9708',
        '#0fcfc0',
        '#f79cd4',
        '#7d87b9',
        '#bb7784',
        '#4a6fe3',
        '#8595e1',
        '#b5bbe3',
        '#e6afb9',
        '#e07b91',
        '#8dd593',
        '#c6dec7',
        '#ead3c6',
        '#f0b98d',
        '#9cded6',
        '#d5eae7',
        '#f3e1eb',
        '#f6c4e1',
        '#bec1d4',
        '#d6bcc0',
    ],
    topBarButton: {
        main: '#efefef',
    },
    topBarButtonText: {
        main: '#000',
    },
    topBarButtonSelected: {
        main: '#fcc',
    },
    background: {
        default: '#D9D9D9',
    },
    primary: {
        main: '#99c',
    },
    secondary: {
        main: '#9ca4d5',
        contrastText: '#fff',
    },
    successButton: {
        main: '#CDDC39',
        contrastText: '#fff',
    },
    success: {
        // main: '#CDDC39',
        main: '#0e0',
        contrastText: '#fff',
        semaphoric: '#0e0',
    },
    error: {
        main: '#d32f2f',
        semaphoric: '#f66',
    },
    warning: {
        // main: '#ed6c02',
        main: '#ee0',
        semaphoric: '#ee0',
    },
    selectedItem: {
        main: '#2962FF',
    },
    tableColumnHeader: {
        main: '#ECEFF1',
    },
    formCard: {
        main: '#FFF',
    },
};

const theme = createTheme(
    {
        palette,
        components: {
            MuiTypography: {
                styleOverrides: {
                    h1: {
                        fontSize: 32,
                        fontWeight: 500,
                    },
                    h2: {
                        fontSize: 28,
                        fontWeight: 400,
                        marginBottom: 15,
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        // borderRadius: 50
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '&.Mui-required .MuiFormLabel-asterisk': {
                            // color: '#F00'
                        },
                    },
                },
            },
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        color: palette.primary.main,
                        '&.Mui-selected, :hover': {
                            color: palette.selectedItem.main,
                            '& .MuiListItemIcon-root': {
                                color: palette.selectedItem.main,
                            },
                        },
                        '&.Mui-selected:hover': {
                            color: palette.selectedItem.main,
                            '& .MuiListItemIcon-root': {
                                color: palette.selectedItem.main,
                            },
                        },
                        '&.disabled': {
                            opacity: 0.5,
                        },
                    },
                },
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        color: palette.primary.main,
                    },
                },
            },
            MuiListItemText: {
                styleOverrides: {
                    root: {
                        color: palette.primary.main,
                    },
                },
            },
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        backgroundColor: palette.formCard.main,
                        '& .MuiDataGrid-columnHeaderTitle': {
                            overflow: 'visible',
                            lineHeight: '1.43rem',
                            whiteSpace: 'normal',
                        },
                        '& .MuiDataGrid-actionsCell': {
                            gridGap: 0,
                        },
                    },
                    cell: {
                        // backgroundColor: 'yellow'
                    },
                    columnHeader: {
                        backgroundColor: palette.tableColumnHeader.main,
                    },
                },
            },
            MuiTabs: {
                styleOverrides: {
                    root: {
                        backgroundColor: palette.formCard.main,
                        marginTop: 20,
                    },
                },
            },
        },
    },
    corePtBR,
    ptBR,
    pickersPtBR
);

export default theme;

import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams, Link, useOutletContext, Outlet } from 'react-router-dom';
import { GenericModelList } from 'drf-react-by-schema';
import Box from '@mui/material/Box';

export default function GenericPageList({
    columnFields,
    hiddenFields = [],
    sumRows = null,
    minWidthFields = null,
    customColumnOperations = null,
    indexField,
    hasBulkSelect = false,
    isAutoHeight = true,
    customLinkDestination = null,
    hasHeader = false,
    PreListComponent = null,
    PostListComponent = null,
    queryParams = undefined,
    customFieldFormLayouts = undefined,
    reloadAfterRowUpdate = undefined,
}) {
    /**
     * @type object
     */
    const context = useOutletContext();
    const pageBasics = context.pageBasics;
    const location = useLocation();
    const pathParts = location.pathname.split('/');
    let model = pathParts[1] || null;
    let indexFieldBasePath = `/${model}/`;
    let indexFieldViewBasePath = `/${model}/detalhe/`;
    if (model === 'settings') {
        model = pathParts[2] || null;
        indexFieldBasePath = `/settings/${model}/`;
    }
    const params = useParams();

    return (
        <>
            <Box>
                <Outlet context={context} />
                {!pageBasics.id && !params.objId && (
                    <>
                        {PreListComponent && <PreListComponent />}
                        <Box className="grid_wrapper">
                            <GenericModelList
                                columnFields={columnFields}
                                hiddenFields={hiddenFields}
                                minWidthFields={minWidthFields}
                                indexField={indexField}
                                hasBulkSelect={hasBulkSelect}
                                sumRows={sumRows}
                                customColumnOperations={customColumnOperations}
                                customFieldFormLayouts={customFieldFormLayouts}
                                reloadAfterRowUpdate={reloadAfterRowUpdate}
                                isAutoHeight={isAutoHeight}
                                customLinkDestination={customLinkDestination}
                                model={model}
                                indexFieldBasePath={indexFieldBasePath}
                                indexFieldViewBasePath={indexFieldViewBasePath}
                                LinkComponent={Link}
                                hasHeader={hasHeader}
                                paginationMode="server"
                                queryParams={queryParams}
                                actions={['editInline', 'edit', 'view']}
                                isEditable={true}
                            />
                            {PostListComponent && <PostListComponent />}
                        </Box>
                    </>
                )}
            </Box>
        </>
    );
}

GenericPageList.propTypes = {
    columnFields: PropTypes.array,
    hiddenFields: PropTypes.array,
    indexField: PropTypes.string,
    sumRows: PropTypes.object,
    minWidthFields: PropTypes.object,
    customColumnOperations: PropTypes.func,
    isAutoHeight: PropTypes.bool,
    customLinkDestination: PropTypes.func,
};
